import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import {blue} from '@material-ui/core/colors';
import {fit} from '@cloudinary/base/actions/resize';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import {Image} from 'components/atoms';
import {HeaderSection, IconAlternate} from 'components/molecules';
import {Section} from 'components/organisms';
import {MSLogoURL} from 'components/atoms/CloudinaryImage';

const useStyles = makeStyles(theme => ({
  root: {
    '& .hero-shaped': {
      borderBottom: 0,
    },
  },
  HeaderSection: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(6, 2, 0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 8, 0, 8),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 8, 0, 8),
    },
  },
  table: {
    marginTop: theme.spacing(1),
  },
  cover: {
    position: 'relative',
    zIndex: 9,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    objectFit: 'contain',
    width: 'auto',
    maxWidth: '80%',
    maxHeight: '500px',
  },
  listItemAvatar: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  checkIcon: {
    color: '#11A31B',
  },
  imageWrapper: {
    width: '165px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '125px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
  container: {
    maxWidth: '1236px',
    margin: 'auto',
    display: 'flex',
    '@media (max-width: 1146px)': {
      display: 'block',
    },
  },
  leftSide: {
    width: '50%',
    '@media (max-width: 1146px)': {
      width: '100%',
    },
  },
  rightSide: {
    width: '50%',
    '@media (max-width: 1146px)': {
      width: '95%',
      margin: 'auto',
    },
  },
}));

const StyledTableCell = withStyles(theme => ({
  root: {
    borderBottom: 'none',
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableCell);

function createData(categories, traditionalUniversity, makeSchool, bootcamp) {
  return {categories, traditionalUniversity, makeSchool, bootcamp};
}

const StyledCheckIcon = withStyles(() => ({
  root: {
    color: '#11A31B',
  },
}))(CheckIcon);

const StyledCrossIcon = withStyles(() => ({
  root: {
    color: '#BF0F0F',
    position: 'relative',
    top: 2,
  },
}))(CloseIcon);

/** ********************** */

const ThreePoints = props => {
  const {data, className, themeMode, ...rest} = props;
  const classes = useStyles();
  const rows = [
    createData('Degree offered', <StyledCheckIcon />, <StyledCheckIcon />, <StyledCrossIcon />),
    createData(
      'Time to complete',
      '4-5 years',
      <Typography variant="h6" color="primary">
        2-2.5 years
      </Typography>,
      '6-12 months'
    ),
    createData(
      'On-campus or online',
      'On-campus',
      <Typography variant="h6" color="primary">
        Both
      </Typography>,
      'Both'
    ),
    createData(
      'Project-based courses',
      <StyledCrossIcon />,
      <StyledCheckIcon />,
      <StyledCrossIcon />
    ),
    createData(
      'Computer science theory',
      <StyledCheckIcon />,
      <StyledCheckIcon />,
      <StyledCrossIcon />
    ),
    createData('Industry collaboration', <StyledCrossIcon />, <StyledCheckIcon />, 'Varies'),
    createData(
      'Tuition',
      '~$150k',
      <Typography variant="h6" color="primary">
        $70k
      </Typography>,
      '~$30k'
    ),
    createData(
      'Unemployment protection',
      <StyledCrossIcon />,
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <StyledCheckIcon />{' '}
        <Typography variant="h6" color="primary">
          EIBR protection
        </Typography>
      </div>,
      'Varies'
    ),
    createData(
      'Average grad salary',
      '~$80k/yr',
      <Typography variant="h6" color="primary">
        $110k/yr
      </Typography>,
      '~$70k/yr'
    ),
  ];
  const theme = useTheme();
  const logoOpts = img =>
    img.resize(fit().height(useMediaQuery(theme.breakpoints.up('sm')) ? 32 : 28));
  const isXS = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });
  const hideBootcamp = useMediaQuery('(max-width: 640px)');
  const hideTraditionalUniversity = useMediaQuery('(max-width: 420px)');
  const reverseIf = (condition, array) => (condition ? array.reverse() : array);
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <HeaderSection
        title={data.title}
        description={data.subtitle}
        className={classes.HeaderSection}
        disableGutter={false}
        fullWidth
      />
      <div className={classes.container}>
        <Section className={classes.leftSide}>
          <List disablePadding>
            {data.points.map((item, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <ListItem disableGutters key={index}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  <IconAlternate size="small" reactIcon={item.icon} color={blue} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.title}
                  secondary={item.subtitle}
                  primaryTypographyProps={{
                    variant: 'h6',
                    className: classes.title,
                    gutterBottom: true,
                  }}
                  secondaryTypographyProps={{
                    variant: 'subtitle1',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Section>
        <div className={clsx(classes.rightSide, classes.cover)}>
          <TableContainer className={classes.table} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell />
                  {reverseIf(
                    hideBootcamp,
                    [
                      !hideTraditionalUniversity && (
                        <StyledTableCell key="traditional-uni" align="center">
                          <Typography variant={isXS ? 'subtitle1' : 'h6'}>
                            Traditional University
                          </Typography>
                        </StyledTableCell>
                      ),
                      <StyledTableCell key="makeschool" align="center">
                        <div className={classes.imageWrapper}>
                          <Image src={MSLogoURL(themeMode === 'dark', logoOpts)} />
                        </div>
                      </StyledTableCell>,
                    ].filter(Boolean)
                  )}
                  {!hideBootcamp && (
                    <StyledTableCell align="center">
                      <Typography variant={isXS ? 'subtitle1' : 'h6'}>Coding bootcamp</Typography>
                    </StyledTableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.categories}>
                    <StyledTableCell align="center">{row.categories}</StyledTableCell>
                    {reverseIf(
                      hideBootcamp,
                      [
                        !hideTraditionalUniversity && (
                          <StyledTableCell key="traditional" align="center">
                            {row.traditionalUniversity}
                          </StyledTableCell>
                        ),
                        <StyledTableCell key="makeschool" align="center">
                          {row.makeSchool}
                        </StyledTableCell>,
                      ].filter(Boolean)
                    )}
                    {!hideBootcamp && (
                      <StyledTableCell align="center">{row.bootcamp}</StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

ThreePoints.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default ThreePoints;
