import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText, Typography} from '@material-ui/core';
import {HeaderSection} from 'components/molecules';
import {Section, Parallax} from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: 'white',
    overflow: 'hidden',
  },
  sectionWrapper: {
    height: 400,
    backgroundColor: '#003c0580',
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1 / 2),
    color: 'white',
    margin: theme.spacing(0, 1, 1, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 2, 0),
    },
  },
}));

const Hero = props => {
  const {className, cover, title, subtitle, date, tags, ...rest} = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Parallax backgroundImage={cover}>
        <div className={classes.sectionWrapper}>
          <Section className={classes.section}>
            <>
              <div className={classes.tags}>
                {tags.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography variant="caption" className={classes.tag} key={index}>
                    {item.toUpperCase()}
                  </Typography>
                ))}
              </div>
              <HeaderSection
                title={title}
                subtitle={subtitle}
                align="left"
                titleProps={{
                  className: clsx(classes.title, classes.textWhite),
                  variant: 'h3',
                }}
                subtitleProps={{
                  className: classes.textWhite,
                }}
              />
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemText
                    primary={date}
                    primaryTypographyProps={{
                      className: classes.textWhite,
                      variant: 'subtitle1',
                    }}
                  />
                </ListItem>
              </List>
            </>
          </Section>
        </div>
      </Parallax>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Cover of the hero
   */
  cover: PropTypes.object.isRequired,
  /**
   * Title of the hero
   */
  title: PropTypes.string.isRequired,
  /**
   * Subtitle of the hero
   */
  subtitle: PropTypes.string.isRequired,
  /**
   * date of the post
   */
  date: PropTypes.string.isRequired,
  /**
   * tags of the post
   */
  tags: PropTypes.array,
};

export default Hero;
