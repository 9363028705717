import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {
  useMediaQuery,
  Toolbar,
  Hidden,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Popover,
} from '@material-ui/core';
import {fit} from '@cloudinary/base/actions/resize';

import {IconButton} from 'gatsby-theme-material-ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import {LoginStateCTA, Image} from 'components/atoms';
import {MSLogoIconURL, MSLogoURL} from 'components/atoms/CloudinaryImage';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth - theme.spacing(8) * 2,
    width: '100%',
    margin: '0 auto',
    padding: '0 auto',
    '@media (max-width: 1164px)': {
      padding: theme.spacing(0, 2),
    },
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('sm')]: {
      width: 120,
      height: 32,
    },
  },
  logoImage: {
    width: 'auto',
    height: '100%',
  },
  logoIconImage: {
    width: 'auto',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
}));

const Topbar = ({themeMode, onSidebarOpen, pages, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState(null);

  const handleClick = (event, popoverId) => {
    setAnchorEl(event.target);
    setOpenedPopoverId(popoverId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const {bachelors, admissions, tuition, login} = pages;

  const MenuGroup = props => {
    const {item} = props;
    return (
      <List disablePadding>
        <ListItem disableGutters>
          <Typography variant="subtitle1" color="primary" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <AnchorLink to={page.href}>
              <Typography
                variant="body1"
                component="a"
                className={clsx(classes.navLink, 'submenu-item')}
                color="textSecondary"
                onClick={handleClose}
              >
                {page.title}
              </Typography>
            </AnchorLink>
          </ListItem>
        ))}
      </List>
    );
  };

  const BachelorsPages = () => {
    const {school, academics, community, outcomes} = bachelors.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={school} />
          <MenuGroup item={academics} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={community} />
          <MenuGroup item={outcomes} />
        </div>
      </div>
    );
  };

  const AdmissionsPages = () => {
    const {admissions: admissionsGroup} = admissions.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={admissionsGroup} />
        </div>
      </div>
    );
  };

  const TuitionPages = () => {
    const {tuition: tuitionGroup, aid} = tuition.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={tuitionGroup} />
          <MenuGroup item={aid} />
        </div>
      </div>
    );
  };

  const LoginMorePages = () => {
    const {login: loginGroup, mediabook, makeSchool} = login.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={loginGroup} />
          <MenuGroup item={mediabook} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={makeSchool} />
        </div>
      </div>
    );
  };

  const renderPages = id => {
    if (id === 'bachelors-in-cs') {
      return <BachelorsPages />;
    }
    if (id === 'admissions') {
      return <AdmissionsPages />;
    }
    if (id === 'tuition-and-aid') {
      return <TuitionPages />;
    }
    return <LoginMorePages />;
  };
  const useIcon = useMediaQuery(
    '(max-width: 310px), (min-width: 355px) and (max-width: 420px), (min-width: 960px) and (max-width: 1048px)'
  );
  const hideCTA = useMediaQuery('(max-width: 355px)');
  const logoOpts = img =>
    img.resize(fit().height(useMediaQuery(theme.breakpoints.up('sm')) ? 32 : 28));

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="Make School">
          <Image
            className={useIcon ? classes.logoIconImage : classes.logoImage}
            src={useIcon ? MSLogoIconURL(logoOpts) : MSLogoURL(themeMode === 'dark', logoOpts)}
            alt="Make School"
            lazy={false}
          />
        </a>
      </div>
      <div className={classes.flexGrow} />
      <List disablePadding className={classes.navigationContainer}>
        <Hidden smDown>
          {[bachelors, admissions, tuition, login].map(page => (
            <div key={page.id}>
              <ListItem
                aria-describedby={page.id}
                onClick={e => handleClick(e, page.id)}
                className={clsx(
                  classes.listItem,
                  openedPopoverId === page.id ? classes.listItemActive : ''
                )}
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={clsx(classes.listItemText, 'menu-item')}
                >
                  {page.title}
                </Typography>
                <ListItemIcon className={classes.listItemIcon}>
                  <ExpandMoreIcon
                    className={openedPopoverId === page.id ? classes.expandOpen : ''}
                    fontSize="small"
                  />
                </ListItemIcon>
              </ListItem>
              <Popover
                elevation={1}
                id={page.id}
                open={openedPopoverId === page.id}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                classes={{paper: classes.popover}}
              >
                <div>{renderPages(page.id)}</div>
              </Popover>
            </div>
          ))}
        </Hidden>
        <LoginStateCTA hideSecondary={hideCTA} />
      </List>
      <Hidden mdUp>
        <IconButton className={classes.iconButton} onClick={onSidebarOpen} aria-label="Menu">
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
