import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'gatsby-theme-material-ui';
import {HeaderSection} from 'components/molecules';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  largeCell: {
    minWidth: 300,
    [theme.breakpoints.down('md')]: {
      minWidth: 200,
    },
  },
}));
const ImportantDates = props => {
  const {openModal} = props;
  const classes = useStyles();
  const admissionsDate = {
    title: 'Important Dates',
    description: (
      <>
        Make School does not have separate First Year and Transfer deadlines. All deadlines
        reflected are for both.
      </>
    ),
    cardContent: {
      title: (
        <Typography variant="h5" width="100%">
          FALL 2021 START
        </Typography>
      ),
      rows: [
        [
          <Typography variant="body2" align="center" color="textSecondary">
            September 22, 2020
          </Typography>,
          <span>
            <Typography variant="h6" color="textSecondary" className={classes.fontWeight500}>
              Fall 2021 Application Opens
            </Typography>
            <Typography variant="body2" color="textSecondary">
              We encourage you to{` `}
              <Link onClick={() => openModal('Important Dates')}>
                {' '}
                sign up for more information from Make School
              </Link>{' '}
              and continue checking this page for updates.
            </Typography>
          </span>,
        ],
        [
          <Typography variant="body2" color="textSecondary" align="center">
            November 10, 2020
          </Typography>,
          <span>
            <Typography variant="h6" color="textSecondary" className={classes.fontWeight500}>
              Fall Application Deadline*
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Decision available by mid-December**
            </Typography>
          </span>,
        ],
        [
          <Typography variant="body2" color="textSecondary" align="center">
            January 12, 2021
          </Typography>,
          <span>
            <Typography variant="h6" color="textSecondary" className={classes.fontWeight500}>
              Winter Application Deadline*
            </Typography>
            <Typography variant="body2">Decision available by mid-March**</Typography>
          </span>,
        ],
        [
          <Typography variant="body2" color="textSecondary" align="center">
            March 9, 2021
          </Typography>,
          <span>
            <Typography variant="h6" color="textSecondary" className={classes.fontWeight500}>
              Spring Application Deadline
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Decision available by mid-May**
              <br />
              Make School will only accept applications and offer rolling admissions after this date
              based on available space in the program.
            </Typography>
          </span>,
        ],
        [
          <Typography variant="body2" color="textSecondary" align="center">
            April 13, 2021
          </Typography>,
          <span>
            <Typography variant="h6" color="textSecondary" className={classes.fontWeight500}>
              Final International Application Deadline
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>
                We will no longer accept new international applications after this date.
              </strong>{' '}
              {` `}
              The earlier deadline for international applications reflects the extra time it takes
              to receive an international admissions decision and Visa appointment wait times. The
              Admissions staff strongly suggest that international applicants apply as early as
              possible to prevent delays.
            </Typography>
          </span>,
        ],
        [
          <Typography variant="body2" color="textSecondary" align="center">
            May 1, 2021
          </Typography>,
          <span>
            <Typography variant="h6" color="textSecondary" className={classes.fontWeight500}>
              Google CSSI: Extension Deadline
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Students must apply by this deadline to be considered eligible for the Google CSSI:
              Extension program at Make School.
            </Typography>
          </span>,
        ],
        [
          <Typography variant="body2" align="center">
            June 29, 2021
          </Typography>,
          <span>
            <Typography variant="h6" className={classes.fontWeight500}>
              Final Application Deadline
            </Typography>
            <Typography variant="body2">
              <strong>We will no longer accept any new applications after this date.</strong>
              {` `}
              The Admissions staff strongly suggest that all applicants apply as early as possible
              to prevent delays.
            </Typography>
          </span>,
        ],
      ],
      note: (
        <Grid container justify="center">
          <Grid item md={11} xs={12}>
            <Typography variant="body2">
              * Make School admissions does not have Early Decision or Early Action deadlines with
              any restrictions. All applicants are encouraged to apply as soon as possible for the
              best experience and for earlier access to applicant- and admit-only community events.
            </Typography>
            <Typography variant="body2">
              ** Deadlines are for submitting the initial application. Additional steps and
              documents are required after submitting the application before applicants are eligible
              for an admissions decision.
            </Typography>
          </Grid>
        </Grid>
      ),
    },
  };
  return (
    <>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12}>
          <HeaderSection title={admissionsDate.title} description={admissionsDate.description} />
        </Grid>
        <Grid item xs={12} md={8}>
          <TableContainer component={Paper}>
            <Table mx={5}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4} className={classes.largeCell}>
                    {admissionsDate.cardContent.title}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {admissionsDate.cardContent.rows.map(row => (
                  <TableRow key={row}>
                    {row.map(el => (
                      <TableCell colSpan={2} align="left">
                        {el}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <caption>{admissionsDate.cardContent.note}</caption>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

ImportantDates.propTypes = {};

export default ImportantDates;
