import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, IconButton, Grid, List, ListItem} from '@material-ui/core';
import {Link} from 'gatsby-theme-material-ui';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {FaDiscord, FaMediumM, FaTwitch} from 'react-icons/fa';
import {Icon} from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
    },
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    // width: 120,
    // height: 32,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 36,
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'grid',
    gridTemplateColumns: '33% 33% 33% ',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  menuItem: {
    alignSelf: 'flex-end',
  },
  menuGroupItem: {
    fontFamily: `LucidaSans-Typewriter`,
    lineHeight: 2.71,
    paddingTop: 0,
    marginTop: 5,
    marginBottom: 10,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white',
    lineHeight: 1,
    letterSpacing: '0.47px',
    marginBottom: 10,
  },
  divider: {
    width: '100%',
  },
  navLink: {
    color: 'rgba(255,255,255,.6)',
  },
  whiteColor: {
    color: '#fff',
  },
  note: {
    lineHeight: 1.71,
  },
  copyright: {
    fontSize: 14,
    lineHeight: 3.8,
  },
  footnote: {
    lineHeight: 1.71,
    margin: 0,
  },
  link: {
    fontSize: 14,
    letterSpacing: ' 0.29px',
    color: 'rgba(255,255,255,.6)',
  },
  legalLink: {
    fontSize: 14,
    letterSpacing: ' 0.29px',
    color: 'rgba(255,255,255,.6)',
    margin: theme.spacing(3, 1),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

const Footer = props => {
  const {pages, footnote, className, ...rest} = props;

  const classes = useStyles();

  const MenuGroup = mgProps => {
    const {item} = mgProps;
    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body2" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body2"
              component="a"
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const Pages = () => (
    <div className={classes.menu}>
      {pages.map((page, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <MenuGroup key={i} item={page} />
      ))}
    </div>
  );

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid item xs={12} md={8} className={classes.menuListContainer}>
          <Grid item>
            <Pages />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List disablePadding>
            <ListItem disableGutters className={classes.logoContainer}>
              <IconButton
                className={classes.socialIcon}
                target="_blank"
                href="https://discord.com/invite/BVYbYFYWw6"
                aria-label="Discord"
              >
                <Icon reactIcon={FaDiscord} className={classes.icon} />
              </IconButton>
              <IconButton
                className={classes.socialIcon}
                target="_blank"
                href="https://www.instagram.com/make_school"
                aria-label="Instagram"
              >
                <InstagramIcon className={classes.icon} />
              </IconButton>

              <IconButton
                className={classes.socialIcon}
                target="_blank"
                href="https://make.sc/youtube"
                aria-label="YouTube"
              >
                <YouTubeIcon className={classes.icon} />
              </IconButton>
              <IconButton
                className={classes.socialIcon}
                target="_blank"
                href="https://www.twitch.com/make-school/"
                aria-label="Twitch"
              >
                <Icon reactIcon={FaTwitch} className={classes.icon} />
              </IconButton>
              <IconButton
                className={classes.socialIcon}
                target="_blank"
                href="https://www.facebook.com/makeschool"
                aria-label="Facebook"
              >
                <FacebookIcon className={classes.icon} />
              </IconButton>
              <IconButton
                className={classes.socialIcon}
                target="_blank"
                href="https://www.twitter.com/makeschool"
                aria-label="Twitter"
              >
                <TwitterIcon className={classes.icon} />
              </IconButton>
              <IconButton
                className={classes.socialIcon}
                target="_blank"
                href="https://www.medium.com/make-school/"
                aria-label="Medium"
              >
                <Icon reactIcon={FaMediumM} className={classes.icon} />
              </IconButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item container xs={12} md={8}>
          {footnote && (
            <Typography
              variant="body2"
              className={clsx(classes.whiteColor, classes.note, classes.marginBottom)}
            >
              {footnote}
            </Typography>
          )}
          <Typography
            variant="body2"
            className={clsx(classes.whiteColor, classes.note, classes.marginBottom)}
          >
            The Bachelor in Applied Computer Science program is offered by the Dominican University
            of California through an{' '}
            <Link
              href="https://www.wscuc.org/content/incubation-policy"
              target="_blank"
              className={classes.link}
            >
              {' '}
              incubation partnership{' '}
            </Link>{' '}
            with MakeSchool.org.
          </Typography>
          <Typography variant="body1" className={clsx(classes.whiteColor, classes.copyright)}>
            ©2021 MakeSchool.org All Rights Reserved.
          </Typography>
          <Typography variant="body1" className={clsx(classes.whiteColor, classes.copyright)}>
            <Link to="/code-of-conduct" title="" className={classes.legalLink}>
              Code of Conduct
            </Link>
            |
            <Link to="/privacy-policy" title="" className={classes.legalLink}>
              Privacy Policy
            </Link>
            |
            <Link to="/terms-of-service" title="" className={classes.legalLink}>
              Terms of Service
            </Link>
          </Typography>
        </Grid>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  footnote: '',
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array,

  /**
   * footer footnote to be rendered
   */
  footnote: PropTypes.string,
};

export default Footer;
