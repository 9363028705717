import {
  NavigationIconAcademicCalendarURL,
  NavigationIconAcademicsURL,
  NavigationIconAdmissionsURL,
  NavigationIconCareerSupportURL,
  NavigationIconCommunityURL,
  NavigationIconCourseCatalogURL,
  NavigationIconFaqsURL,
  NavigationIconParentsURL,
  NavigationIconStudentLifeURL,
  NavigationIconTuitionURL,
} from '../../atoms/CloudinaryImage';

const discoverData = {
  title: "Discover what it's like to become a student",
  icons: [
    {
      icon: NavigationIconAcademicsURL(),
      content: 'ACADEMICS',
      to: '/computer-science-degree/academics/',
    },
    {
      icon: NavigationIconAdmissionsURL(),
      content: 'ADMISSIONS',
      to: '/computer-science-degree/admissions/',
    },
    {
      icon: NavigationIconStudentLifeURL(),
      content: 'STUDENT LIFE',
      to: '/computer-science-degree/student-life/',
    },
    {
      icon: NavigationIconCareerSupportURL(),
      content: 'OUTCOMES',
      to: '/computer-science-degree/outcomes/',
    },
    {
      icon: NavigationIconFaqsURL(),
      content: 'FAQS',
      to: '/computer-science-degree/faq/',
    },
    {
      icon: NavigationIconCommunityURL(),
      content: 'OUR COMMUNITY',
      to: '/computer-science-degree/community/community/students/',
    },
    {
      icon: NavigationIconParentsURL(),
      content: 'PARENTS',
      to: '/computer-science-degree/parents/',
    },
    {
      icon: NavigationIconTuitionURL(),
      content: 'TUITION AND FINANCIAL AID',
      to: '/computer-science-degree/tuition-and-aid/',
    },
    {
      icon: NavigationIconAcademicCalendarURL(),
      content: 'ACADEMIC CALENDAR',
      to: 'https://make.sc/academic-calendar',
    },
    {
      icon: NavigationIconCourseCatalogURL(),
      content: 'COURSE OFFERINGS',
      to: '/course-offerings/',
    },
  ],
};

export default discoverData;
