import React from 'react';
import PropTypes from 'prop-types';
import {HeaderSection} from 'components/molecules';
import {Grid, Typography, useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CardBase} from 'components/organisms';
import {Image} from 'components/atoms';
import {useTheme} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 348,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  withPadding: {
    padding: theme.spacing(3),
  },
  noPadding: {
    padding: 0,
  },
}));
const GetInvolved = props => {
  const {data, className, rest} = props;
  const classes = useStyles();
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container className={className} {...rest}>
      <HeaderSection title={data.title} />
      <Grid container spacing={4} justify="center" alignContent="center">
        {data.cards.map(card => (
          <Grid item xs={12} md={isMd ? 6 : 4}>
            <CardBase withShadow cardContentProps={{className: classes.card}}>
              {card.imgUrl ? (
                <Image src={card.imgUrl} alt={card.alt} className={classes.noPadding} />
              ) : (
                <Grid item alignContent="center" justify="center" className={classes.withPadding}>
                  <Typography variant="h5" align="center">
                    {card.title}
                  </Typography>
                  <Typography variant="body1">{card.description}</Typography>
                </Grid>
              )}
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

GetInvolved.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default GetInvolved;
