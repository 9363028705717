export const light = {
  alternate: {
    main: 'rgb(247, 249, 250)',
    dark: '#e8eaf6',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#2D6494',
    light: 'rgb(71, 145, 219)',
    dark: 'rgb(17, 82, 147)',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#2d3748',
    secondary: '#718096',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fff',
    default: '#fff',
    level2: '#f5f5f5',
    level1: '#fff',
    footer: '#2D3748',
  },
  legacy: {
    actionHighlight: {
      quote: {
        primary: 'grey',
        secondary: '#f5f5f5',
      },
      info: {
        primary: '#618fff',
        secondary: '#F8FBFF',
      },
      action: {
        primary: '#FFA342',
        secondary: '#FFFAF5',
      },
      solution: {
        primary: '#decf22',
        secondary: '#FFFEF4',
      },
      challenge: {
        primary: '#5AFF42',
        secondary: '#efffed',
      },
    },
    syntaxHighlight: {
      reserved: '#A90D91',
      literal: '#1C01CE',
      comment: '#177500',
      special: '#C41A16',
      preproc: '#633820',
      attribute: '#836C28',
      className: '#3F6E75',
      character: '#2300CE',
      psuedo: '#5B269A',
      background: '#f5f5f5',
    },
  },
  marketingPage: {
    black: ' #000000',
    white: ' #ffffff',
    red: '#d43a3a',

    faintGrey: ' #f5f6f8',
    lightGrey: ' #dfdfdf',
    grey: ' #87898c',
    mediumGrey: ' #454c4c',
    darkGrey: ' #303030',

    faintBlue: ' #e8eef3',
    mediumBlue: ' #12739e',
    lightBlue: ' #4bb1e4',
    darkBlue: ' #0d4062',
    turquoise: ' #00c7c5',
    backendWebRed: {
      primary: '#EC6C5C',
      tint1: '#F0897D',
      tint2: '#F4A79D',
    },
    mobileDevGreen: {
      primary: '#08B885',
      tint1: '#39C69D',
      tint2: '#6BD4B6',
    },
    frontEndWebOrange: {
      primary: '#FAB56F',
      tint1: '#FBC48C',
      tint2: '#FCD3A9',
    },
    computerScienceBlue: {
      primary: '#1368A5',
      tint1: '#4286B7',
      tint2: '#71A4C9',
    },
    dataSciencePurple: {
      primary: '#B16BB0',
      tint1: '#C189C0',
      tint2: '#D0A6D0',
    },
    nonTrackCoursesGrey: {
      primary: '#6A6E73',
      tint1: '#888B8F',
      tint2: '#A6A8AB',
    },
    adminColors: {
      red: '#BF0F0F',
      green: '#11A31B',
      orange: '#E58B32',
    },
  },
};

export const dark = {
  alternate: {
    main: '#2D3748',
    dark: '#24242b',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  type: 'dark',
  primary: {
    main: '#90caf9',
    light: 'rgb(166, 212, 250)',
    dark: 'rgb(100, 141, 174)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  secondary: {
    light: '#ffb74d',
    main: '#f9b934',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF',
    secondary: '#AEB0B4',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#1A202C',
    default: '#121212',
    level2: '#333',
    level1: '#2D3748',
    footer: '#18181f',
  },
  legacy: {
    actionHighlight: {
      quote: {
        primary: 'grey',
        secondary: '#292929',
      },
      info: {
        primary: '#618fff',
        secondary: '#002e4f',
      },
      action: {
        primary: '#FFA342',
        secondary: '#432300',
      },
      solution: {
        primary: '#faec48',
        secondary: '#282600',
      },
      challenge: {
        primary: '#5AFF42',
        secondary: '#022800',
      },
    },
    syntaxHighlight: {
      reserved: '#e034c1',
      literal: '#7768d9',
      comment: '#64b350',
      special: '#cf4b46',
      preproc: '#ab5320',
      attribute: '#ecb54b',
      className: '#82b3be',
      character: '#af67e9',
      psuedo: '#9544ff',
      background: '#333333',
    },
  },
};
