import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {Link} from 'gatsby-theme-material-ui';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      background: theme.palette.alternate.main,
      textDecoration: 'underline',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  icon: {
    marginLeft: theme.spacing(1.5),
  },
}));

/**
 * Component to display the "Learn More" link
 *
 * @param {Object} props
 */
const LearnMoreLink = props => {
  const {color, variant, title, href, className, iconProps, typographyProps, external} = props;

  const classes = useStyles();

  const children = (
    <>
      <Typography
        component="span"
        className={clsx('learn-more-link__typography', classes.title)}
        variant={variant}
        color={color || 'primary'}
        {...typographyProps}
      >
        {title}
      </Typography>
      <Typography
        component="span"
        className={clsx('learn-more-link__typography', classes.icon)}
        variant={variant}
        color={color || 'primary'}
        {...iconProps}
      >
        ➔
      </Typography>
    </>
  );

  return (
    <>
      {external ? (
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className={clsx('learn-more-link', classes.root, className)}
        >
          {children}
        </a>
      ) : (
        <Link to={href} className={clsx('learn-more-link', classes.root, className)}>
          {children}
        </Link>
      )}
    </>
  );
};

LearnMoreLink.defaultProps = {
  variant: 'subtitle1',
  href: '#',
  typographyProps: {},
  iconProps: {},
  component: 'a',
};

LearnMoreLink.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The component to load as a main DOM
   */
  component: PropTypes.oneOf(['Link', 'a']),
  /**
   * Title of the link
   */
  title: PropTypes.string.isRequired,
  /**
   * Variant of the link
   */
  variant: PropTypes.oneOf(['h6', 'h4', 'subtitle1', 'subtitle2', 'body1', 'body2']),
  /**
   * Href of the link
   */
  href: PropTypes.string,
  /**
   * Color of the link
   */
  color: PropTypes.string,
  /**
   * Additional properties to pass to the Icon component
   */
  iconProps: PropTypes.object,
  /**
   * Additional properties to pass to the Typography component
   */
  typographyProps: PropTypes.object,
};

export default LearnMoreLink;
