import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import {indigo} from '@material-ui/core/colors';
import {Image} from 'components/atoms';
import {HeaderSection, IconAlternate} from 'components/molecules';

const useStyles = makeStyles(theme => ({
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  coverImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
    },
  },
}));

const ListWithImage = props => {
  const {title, image, subtitle, data, className, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const headerTitle = title ?? (
    <span>
      How to{' '}
      <Typography component="span" variant="inherit" color="primary">
        Apply
      </Typography>
      <br />
    </span>
  );

  const imageSrc =
    image ?? 'https://assets.maccarianagency.com/the-front/illustrations/dashboard-drawing.svg';
  return (
    <div className={className} {...rest}>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item container justify="center" alignItems="center" xs={12} md={6}>
          <Image src={imageSrc} alt="..." className={classes.coverImage} />
        </Grid>
        <Grid item xs={12} md={6}>
          <HeaderSection title={headerTitle} subtitle={subtitle} align="left" disableGutter />
          <List disablePadding>
            {data.map(item => (
              <ListItem disableGutters key={item.title}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  <IconAlternate size="small" reactIcon={item.icon} color={indigo} />
                </ListItemAvatar>
                <ListItemText primary={item.title} secondary={item.subtitle} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

ListWithImage.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ).isRequired,
};

export default ListWithImage;
