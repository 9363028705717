import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Toolbar, Box} from '@material-ui/core';
import {Image, DarkModeToggler} from 'components/atoms';
import Logo from '../../../../assets/images/logos/logo-grey.svg';
import LogoDark from '../../../../assets/images/logos/logo-white.svg';

const useStyles = makeStyles(theme => ({
  toolbar: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));

const Topbar = ({themeMode, themeToggler, className, ...rest}) => {
  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.toolbar, className)} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="thefront">
          <Image
            className={classes.logoImage}
            src={themeMode === 'light' ? Logo : LogoDark}
            alt="thefront"
            lazy={false}
          />
        </a>
      </div>
      <Box flexGrow={1} />
      <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  themeMode: PropTypes.string.isRequired,
  themeToggler: PropTypes.func.isRequired,
};

export default Topbar;
