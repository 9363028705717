import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Typography} from '@material-ui/core';
import {Button, Link} from 'gatsby-theme-material-ui';
import {HeaderSection} from 'components/molecules';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  headerTitle: {
    letterSpacing: 'normal',
    lineHeight: 1.04,
    textAlign: 'center',
  },
  subtitle: {
    lineHeight: 2.1,
    color: theme.palette.marketingPage?.darkBlue,
    marginTop: '60px',
  },
  bodyText: {
    fontWeight: 400,
    lineHeight: 2,
    '& ul': {
      marginLeft: '35px',
    },
    '& ol': {
      marginLeft: '35px',
    },
  },
  button: {
    marginRight: '19px',
  },
  whiteText: {
    color: 'white',
  },
  buttonsWrapper: {
    marginTop: '15px',
  },
}));

const Hero = props => {
  const {
    title,
    subtitle,
    buttons,
    description,
    descriptionVariant,
    descriptionClassName,
    gridClassName,
    titleVariant,
    subtitleVariant,
    ...rest
  } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container direction="column" alignItems="center" className={gridClassName} {...rest}>
      {title && (
        <Grid item md={9}>
          <HeaderSection
            titleVariant={titleVariant}
            titleProps={{
              className: clsx('', classes.headerTitle),
              color: 'body',
            }}
            title={title}
          />
        </Grid>
      )}
      <Grid item md={9} className={descriptionClassName}>
        {subtitle && (
          <HeaderSection
            subtitle={subtitle}
            subtitleVariant={subtitleVariant}
            subtitleProps={{
              className: clsx(classes.subtitle),
              color: 'primary',
            }}
          />
        )}
        {description && (
          <Typography
            align="left"
            variant={descriptionVariant}
            className={clsx(classes.bodyText, descriptionClassName)}
          >
            {description}
          </Typography>
        )}
      </Grid>
      {buttons && (
        <Grid className={clsx(classes.buttonsWrapper)} item md={4}>
          {buttons.map(button => (
            <Button
              className={classes.button}
              size={isMd ? 'large' : 'medium'}
              variant={button.variant}
              color={button.color}
            >
              <Link
                className={button.variant === 'contained' && classes.whiteText}
                to={button.to && button.to}
                href={button.href && button.href}
              >
                {button.text}
              </Link>
            </Button>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
Hero.propTypes = {
  /**
   * Title to be rendered
   */
  title: PropTypes.string,
  /**
   * Title variant
   */
  titleVariant: PropTypes.string,
  /**
   * Subtitle to be rendered
   */
  subtitle: PropTypes.string,
  /**
   * Subtitle variant
   */
  subtitleVariant: PropTypes.string,
  /**
   * description to be rendered
   */
  description: PropTypes.string,
  /**
   * description variant
   */
  descriptionVariant: PropTypes.string,
  /**
   * description className
   */
  descriptionClassName: PropTypes.string,
  /**
   * grid className
   */
  gridClassName: PropTypes.string,
  /**
   * Array containing the buttons to be displayed
   */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf[('default', 'inherit', 'primary', 'secondary')],
      variant: PropTypes.oneOf[('contained', 'outlined', 'text')],
      text: PropTypes.string,
      to: PropTypes.string,
    })
  ),
};

Hero.defaultProps = {
  titleVariant: 'h4',
  subtitleVariant: 'h5',
  descriptionVariant: 'body1',
};

export default Hero;
