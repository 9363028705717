import {Typography} from '@material-ui/core';
import React from 'react';
import microsoft from 'assets/images/accelerated-degree/microsoftLogo.svg';
import apple from 'assets/images/accelerated-degree/appleLogo.svg';
import nasa from 'assets/images/accelerated-degree/nasaLogo.svg';
import amazon from 'assets/images/accelerated-degree/amazonLogo.svg';
import twitch from 'assets/images/accelerated-degree/twitchLogo.svg';
import zendesk from 'assets/images/accelerated-degree/zendeskLogo.svg';
import facebook from 'assets/images/accelerated-degree/facebookLogo.svg';
import bloomberg from 'assets/images/accelerated-degree/bloombergLogo.svg';
import tesla from 'assets/images/accelerated-degree/teslaLogo.svg';
import shopify from 'assets/images/accelerated-degree/shopify_logo_whitebg.svg';
import {
  FaUniversity,
  FaGlobeAmericas,
  FaHandHoldingUsd,
  FaGraduationCap,
  FaToolbox,
  FaHands,
  FaCommentDollar,
  FaUserGraduate,
  FaChalkboardTeacher,
  FaHandHoldingHeart,
} from 'react-icons/all';

export const employers = {
  topOutComesTagline: (
    <span>
      <span style={{display: 'inline-block'}}>Our graduates work at</span>{' '}
      <span style={{display: 'inline-block'}}>top companies</span>{' '}
      <span style={{display: 'inline-block'}}>
        earning{' '}
        <Typography component="span" variant="inherit" color="primary">
          $108,000/yr
        </Typography>{' '}
        <span style={{display: 'inline-block'}}>on average</span>
      </span>{' '}
      <span style={{display: 'inline-block'}}>out of Make School</span>
    </span>
  ),
  topOutcomesSummary: '',
  logos: [
    {
      logo: microsoft,
      aspectRatio: 2,
    },
    {
      logo: apple,
      aspectRatio: 2,
    },
    {
      logo: nasa,
      aspectRatio: 1,
    },
    {
      logo: amazon,
      aspectRatio: 1,
    },
    {
      logo: twitch,
      aspectRatio: 3,
    },
    {
      logo: zendesk,
      aspectRatio: 1,
    },
    {
      logo: facebook,
      aspectRatio: 2,
    },
    {
      logo: bloomberg,
      aspectRatio: 2,
    },
    {
      logo: tesla,
      aspectRatio: 2,
    },
    {
      logo: 'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/ProductCollege/Google_Logo.svg',
      aspectRatio: 2,
    },
    {
      logo: 'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/ProductCollege/Linkedin_Logo.svg',
      aspectRatio: 2,
    },
    {
      logo: 'https://res.cloudinary.com/makeschool/image/upload/v1605369161/Homepage/companies/spotify.svg',
      aspectRatio: 2,
    },
    {
      logo: 'https://res.cloudinary.com/makeschool/image/upload/v1537203877/Homepage/companies/ycombinator.svg',
      aspectRatio: 2,
    },
    {
      logo: shopify,
      aspectRatio: 2,
    },
  ],
};

export const board = {
  title: 'Meet our Board',
  paragraph1:
    'Make School maintains an independent Board of Trustees that exercises appropriate authority over institutional integrity, policies, and ongoing operation. The board is composed of members with the diverse qualifications (see below) required to govern an institution of higher learning and regularly engages in self-review and training to enhance its effectiveness.',
  paragraph2:
    'The directors in office elect their successors such that the Board of Directors is self- perpetuating. Each director is elected for a term of two years and holds office until a successor has been elected. The Board meets five times a year during each academic term to hold leadership accountable to Make School’s mission, values, and operational goals.',
  boardList: [
    {
      name: 'Lisa Gelobter',
      term: '3 years',
      bio: [
        'Lisa Gelobter is the CEO and Co-founder of tEQuitable. Using technology to make workplaces more equitable, tEQuitable provides an independent, confidential platform to address issues of bias, harassment, and discrimination. Lisa has pioneered Internet technologies that have been used by billions of people including Shockwave and the ascent of online video. Most recently, she worked at the Obama White House, serving as the Chief Digital Service Officer for the Department of Education. Previously, Lisa acted as the Chief Digital Officer for BET Networks and was also a member of the senior management team for the launch of Hulu.',
        'Lisa is one of Inc.‘s 100 Women Building America’s Most Innovative and Ambitious Businesses, Fast Company’s Most Creative People, is spotlighted in Eric Ries’ book “The Startup Way”, and serves on the Board/Steering Committees of: Times Up Tech, /dev/color, the Obama Foundation’s Tech Council, and The Education Trust. Lisa is one of the first 40 Black women ever to have raised over $1mm in VC funding. She is also proud to be a Black woman with a degree in Computer Science. Go STEM!',
      ],
      table: 'first',
    },
    {
      name: 'Nicole Alston',
      term: '3 years',
      bio: [
        'Nicole Alston is a Vice President of Legal at Google and has led YouTube Legal for 9 years. In this role, Nicole faces many of YouTube’s toughest legal challenges, from copyright conundrums to tough freedom of expression calls. Early on, Nicole was commercial counsel for many of Google’s strategic advertising syndication agreements and led the early legal negotiations of Google’s contracts for Google Maps and Earth. She managed the commercial teams handling content agreements for Google Maps, Earth, Finance, Book Search, Product Search and YouTube and helped to negotiate Google’s landmark copyright settlement agreement for Google Books. Prior to Google, Nicole was Director, Business and Legal Affairs at Guru Worldwide, and before that was an attorney in the Corporate and Securities Group at the law firm of Gray Cary Ware & Freidenrich LLP (now DLA Piper). She is an executive sponsor of YouTube’s Black Affinity Group and a member of Google’s Black Leadership Advisory Group. She previously served on the Board of Trustees of The Carey School. Nicole received her J.D. and B.A. degrees from the University of California, Los Angeles.',
      ],
      table: 'second',
    },
    {
      name: 'Tim Draper',
      term: '3 years',
      bio: [
        'Tim Draper founded Draper Associates, DFJ and the Draper Venture Network, a global network of venture capital funds. Funded Baidu, Tesla, Skype, SpaceX, Twitch, Hotmail, Focus Media, Robinhood, Athenahealth, Box, Cruise Automation, Carta, Planet, PTC and 15 other unicorns from the seed stage.',
        'He is a supporter and global thought leader for entrepreneurs everywhere, and is a leading spokesperson for Bitcoin, Blockchain, ICOs and cryptocurrencies, having won the Bitcoin US Marshall’s auction in 2014, invested in over 50 crypto companies, and led investments in Coinbase, Ledger, Tezos, and Bancor, among others.',
        'In promoting entrepreneurship, he created Draper University of Heroes, a residential and online school based in San Mateo, Ca to help extraordinary people accomplish their life missions. The school has trained 1200 entrepreneurs, from 84 different countries, who have built 400 companies including NVision, and crypto leaders, QTUM, Spacecash, and DataWallet. He started Innovate Your State, a non-profit dedicated to crowdsource innovation in government, and BizWorld, a non-profit that teaches young children how business and entrepreneurship work.',
        'He served on the California State Board of Education, and led a movement for Local Choice in schools culminating in a statewide initiative for School Vouchers.',
        'Tim Draper received a Bachelor of Science from Stanford University with a major in electrical engineering and a Master of Business Administration from the Harvard Business School. He has two honorary doctorates from The International University and Trinity College of Dublin.',
      ],
      table: 'second',
    },
    {
      name: 'Asra Nadeem',
      term: '3 years',
      bio: [
        'Asra Nadeem is the President at Draper University. As a global business builder, Asra designs and operationalizes successful strategies to accelerate revenue, product advantage, and market position in intensely competitive technology sectors in emerging markets. She has previously worked on product and market development for startups in the Middle East, North Africa, and Southeast Asia.',
        'Asra has:',
        <div>
          <ul>
            <li>
              Led product for the first matchmaking service for Muslims and scaled it to 1.5M users
            </li>
            <li>
              Managed product and growth for the largest employment marketplace in Pakistan and KSA
              (investors: DJF, Reid Hoffman)
            </li>
            <li>
              Built and scaled HRMS and virtual events (vfairs) for governments in the MENA region
            </li>
          </ul>
        </div>,
      ],
      table: 'first',
    },
    {
      name: 'Troy "Roc" Ryder',
      term: '3 years',
      bio: [
        'Mr. Ryder is a principal with Credit Global and a board member for both venture companies and non-profit institutions.',
        'His start up career began when he co-founded the Constitution Insurance Company where he was responsible for developing innovative insurance products. He adopted the entrepreneurial spirit and quickly entered into other ventures.',
        'Roc’s passion for entrepreneurism led him to becoming a key member of Draper University. Throughout the last several years he has trained thousands of Heroes who collectively created billions in wealth. He has partnered with several universities and numerous governments helping them nurture venture eco-systems.',
        'Prior to becoming an entrepreneur, Mr. Ryder was a seasoned financial services executive. He was a Vice President with Morgan Stanley where he was responsible for all sales and marketing efforts in the North Atlantic area. Mr. Ryder’s financial services career had numerous highlights. Most notably he played a lead role in an initial public offering and served as an advisor for several nationally recognized insurance companies.',
        'He served in the U.S. Army and graduated from the elite Army Ranger School. He received his Bachelor of Science Degree from the United States Military Academy, West Point, NY.',
      ],
      table: 'first',
    },
    {
      name: 'Carol Lo',
      term: '3 years',
      bio: [
        'Carol has over 15 years of higher education experience in admissions, curriculum design & management, and program operations. Carol was the founding Chief Operating Officer of Draper University, a leading entrepreneurship program in Silicon Valley, where she helped build partnerships with universities and various programs including online and executive programs.',
        'Carol is the former Acting Executive Director and Director of Operations of the Berkeley-Columbia Executive MBA program at UC Berkeley’s Haas School of Business. Her role included program management, student services, and admissions, in conjunction with Columbia Business School. She is a former Assistant Director of Admission at Stanford University. She is also an alumni interviewer for London Business School and private college admissions counselor. Carol has also worked in Consulting and Finance in addition to Education. Carol received a BS in Business Administration from UC Berkeley and an MBA from London Business School.',
      ],
      table: 'second',
    },
    {
      name: 'Andrew Tang',
      term: '3 years',
      bio: [
        'Andrew Tang is a partner at Draper DragonFund and Draper Associates, investing in early-stage technology companies and assisting with their business development, particularly international market entry strategy and partnerships.',
        'He also serves as CEO of Draper University, which is an incubator and entrepreneurship education program located in Silicon Valley. In his spare time, he teaches finance and fundraising classes to student entrepreneurs.',
        'Tang has over 20 years of operating, research and development, investment banking and investing experience in the high-tech space. He co founded Draper DragonFund in 2006 to focus on cross-border investing. He has led and managed investments in companies such as VeChain, Ledger, CloudMedx, Yeepay, Jin Jing Electric Motors, Alto Beam, Vicarious Artificial Intelligence, Broadbus (MOT), to name a few.',
        'Prior to starting his fund, he was a partner and member of the investment committee at ABB Technologies Ventures and Infineon Ventures. Prior to that, he was an investment banker with Credit Suisse First Boston’s technology group in Palo Alto, where he worked on the Magma IPO (SNPS) and Cyras (CIEN) acquisition. Before joining Credit Suisse, Tang was a senior engineer and marketing manager at Intel and was responsible for chip design and market enabling for Pentium and Itanium processors.',
        'Tang grew up in Taiwan and moved to Arlington, Texas, at age 13. He completed his undergraduate degree in electrical and computer engineering at The University of Texas at Austin, earned a master’s degree in electrical engineering and computer science from MIT, and received an MBA from the Wharton School of the University of Pennsylvania. He holds a U.S. patent in silicon germanium thin film materials.',
      ],
      table: 'first',
    },
    {
      name: 'Rob Urstein',
      term: '3 years',
      bio: [
        'Rob Urstein is a Senior Strategist at Entangled Solutions and Entrepreneur-in-Residence at Entangled Group. He is also a Lecturer in Management at the Stanford Graduate School of Business, where he teaches courses on innovation in higher education. Rob has more than 20 years of professional experience as an academic leader, teacher, and researcher, working extensively with undergraduates, graduate students, executives, and faculty members. He is a governing board member of the College Transition Collaborative.',
        'Rob spent more than a dozen years in varied leadership roles at Stanford University, including Associate Vice Provost for Undergraduate Education, Dean of Freshmen, and Director of Undergraduate Advising and Research. At the Stanford Graduate School of Business, Rob served for eight years as Assistant Dean and for two years as Managing Director of Global Innovation Programs, overseeing a portfolio of on-campus and international programs focused on leadership, innovation, and entrepreneurship. He also spent a year as a Fulbright Senior Scholar in Oslo, Norway, working for the Royal Norwegian Ministry of Education. Rob earned his undergraduate degree from Claremont McKenna College, and his M.A. and Ph.D. from the University of Iowa.',
      ],
      table: 'second',
    },
    {
      name: 'Vivian Lufkin',
      term: '3 years',
      bio: [
        'Vivian is the Executive Director of Draper Hero Institute, (a 501c3 nonprofit organization to develop and promote entrepreneurial career paths). She implemented the recent launch of the Draper Innovation Index, which tracks the growth trends of innovation and entrepreneurship across the globe and the US.',
        'Vivian brings a broad range of expertise in the areas of nonprofit management, philanthropic engagement, fundraising, event planning, marketing and creative direction to help lead DHI’s efforts both nationally and globally. Prior to DHI, she governed and served as co-chair to Bullis Charter School Foundation exceeding annual fundraising goals during her 3-year term.',
        'As a long time Silicon Valley resident and industry networker for over 30 years, Vivian has developed key relationships that help connect current “heroes” with aspiring entrepreneurs throughout the world.',
      ],
      table: 'second',
    },
    {
      name: 'Ashutosh Desai',
      term: '3 years',
      bio: [
        'Ashutosh Desai is a founder of Make School focused on long-term vision/strategy, organizational design, brand, and new products. He started his career as a software entrepreneur in high school before dropping out of UCLA to found Make School. He helped build curriculum for the first iPhone app development courses at MIT, UC Berkeley, and Carnegie Mellon, and he has given lectures to MBA students at Stanford and INSEAD. He is passionate about building Make School into a platform for societal impact, enabling a diverse community of technologists to help tackle the world’s largest problems.',
      ],
      table: 'second',
    },
    {
      name: 'Jeremy Rossmann',
      term: '3 years',
      bio: [
        "Jeremy Rossmann is a founder and CEO of Make School focused on managing day-to-day operations and fundraising. He left MIT to co-found Make School in 2012. He is passionate about bridging the gap between higher education and industry needs. He built and taught the first iPhone app development courses ever offered at MIT, UC Berkeley, and Carnegie Mellon, has lectured at over 100 high school and university hackathons worldwide, and led a multi-year partnership with the Thurgood Marshall College Fund to prepare HBCU students for internships at Apple. More recently, he spearheaded Make School's incubation partnership with Dominican University.",
      ],
      table: 'first',
    },
  ],
};

export const services = [
  {
    icon: FaUniversity,
    title: 'WSCUC Accreditation',
    subtitle:
      'Our degree is offered through a partnership with Dominican University of California and we’ve applied for independent accreditation to spin off in 2022.',
  },
  {
    icon: FaGlobeAmericas,
    title: 'Diversifying Tech',
    subtitle:
      '32% of our employed alumni identify as a member of a historically underrepresented group in Tech (URGT).',
  },
  {
    icon: FaHandHoldingUsd,
    title: 'Downside Protection',
    subtitle:
      'Make School pays student loans for unemployed grads. If they don’t qualify for loans, we provide ISA.',
  },
  {
    icon: FaGraduationCap,
    title: 'Accelerated Bachelor’s Degree',
    subtitle:
      'We increase time in the workforce and reduce drop-out risk through an intensive pathway that grants a 4-year degree in 2-2.5 years.',
  },
];

export const sideBySide = [
  {
    label: '',
    title: 'No shortcuts',
    description:
      'Make School delivers an accredited Bachelors in Applied Computer Science that pairs a project-based and career-focused computer science education with strong foundations in the liberal arts. Students are equipped with the skills they need to compete with Ivy+ graduates for the most selective jobs in tech.',
    illustration: 'presentationAndPitching',
    ctaText: '',
  },
  {
    label: '',
    title: 'Student support for student success',
    description: (
      <span>
        Make School maintains a 90% 1-year retention rate through investments in individualized
        coaching, free therapy for students, dedicated student support staff for BIPOC students, and
        a 1:1 industry mentorship program. Our{' '}
        <a
          href="https://www.makeschool.com/computer-science-degree/tuition-and-aid#eibr"
          target="_blank"
          rel="noreferrer"
        >
          EIBR
        </a>{' '}
        fund will make monthly student loan payments for any unemployed grads, aligning incentives
        between school and student and protecting our most vulnerable students from financial
        hardship.
      </span>
    ),
    illustration: 'peerMentorship',
    ctaText: '',
  },
];

export const partners = [
  {
    name: 'Google',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/ProductCollege/Google_Logo.svg',
  },
  {
    name: 'Facebook',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Homepage/companies/facebook-wordmark.svg',
  },
  {
    name: 'Microsoft',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/ProductCollege/Microsoft_Logo.svg',
  },
  {
    name: 'LinkedIn',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/ProductCollege/Linkedin_Logo.svg',
  },
  {
    name: 'Spotify',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/v1605369161/Homepage/companies/spotify.svg',
  },
  {
    name: 'Y Combinator',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/v1537203877/Homepage/companies/ycombinator.svg',
  },
];

export const about = {
  title: (
    <span>
      About{' '}
      <Typography component="span" variant="inherit" color="primary">
        Make School
      </Typography>
      <br />
    </span>
  ),
  image: 'peerMentorship',
  data: [
    {
      icon: FaToolbox,
      title: 'Dynamic curriculum',
      subtitle:
        'Our curriculum is industry-informed and project-based, including the liberal arts. Make School seeks to prepare our students to be engaged citizens in an increasingly complex civic society, requiring them to have the skills necessary to identify misinformation, model complex policy issues, and interpret the nuanced history that bred the systems which govern our world.',
    },
    {
      icon: FaHands,
      title: 'Holistic Student Support',
      subtitle:
        'In the last year, we have invested in dedicated student support staff for BIPOC students, access to free therapy, diverse faculty, individualized coaching, a 1:1 industry mentorship program, and post-grad career services.',
    },
    {
      icon: FaCommentDollar,
      title: 'Proven Results',
      subtitle:
        'Our alumni’s average starting salary is $108K, and $114K for URGT students, on par with Stanford and MIT. Our graduates go on to work at top companies including Microsoft, Amazon, Google, and Facebook.',
    },
  ],
};

export const testimonials = {
  screenshots: {
    arianeEvans: {
      image: 'arianeEvans',
      link: 'https://www.linkedin.com/in/arianeevans/?trk=public_post-embed_share-update_actor-text',
      alt: `After many months of hard work and dedication, I am happy to say that I have accepted an offer as a Program Manager at Microsoft! I would have never expected this when I first started college as an Acting Major, but am glad I found my true calling at Make School, and am excited to be working for an amazing company! Thank you to all those that have helped me reach my goals, I couldn't have done it without you.`,
    },
    salvadorBecerra: {
      image: 'salvadorBecerra',
      link: 'https://www.linkedin.com/posts/salvadorbecerra_after-2-years-at-make-school-im-happy-to-activity-6726966078758637568-ya_Q',
      alt: 'After 2 years at Make School I’m happy to say I accepted an offer at Microsoft! When I dropped out of college 4 years I had no idea what I was gonna do and now I’m gonna be working at my dream company! I couldn’t have done it without help from others so if anyone needs advice please message me!',
    },
    nyapalGatkuoth: {
      image: 'nyapalGatkuoth',
      link: 'https://www.facebook.com/nyapal.gatkuoth.1/posts/318994929115560',
      alt: 'Just finishing up my first month at Autodesk and it has been a dream. My team and managers are all amazing & today my code passed review & I completed my first sprint on the job. Everyone who said working is easier than MS was telling the truth.',
    },
  },
  seve: {
    feedback:
      "It's kinda weird seeing myself head to head, toe to toe with students from schools like Stanford and Harvard. Make School is incredibly good at preparing you for a job.",
    authorName: 'Severiano Badajoz',
    authorOccupation: 'Software Engineer, Chan Zuckerberg Initiative',
    authorPhoto: 'severiano',
  },
};

export const support = {
  title: (
    <span>
      Support{' '}
      <Typography component="span" variant="inherit" color="primary">
        Make School
      </Typography>
      <br />
    </span>
  ),
  image: 'demoNightClapping',
  imageAlt: 'Make School students working together at a whiteboard.',
  data: [
    {
      icon: FaUserGraduate,
      title: 'Support our Students',
      subtitle:
        'Your contribution will support our investments in student support, 1:1 coaching, equity initiatives, career services, mental health support, and living stipends for low-income students.',
    },
    {
      icon: FaChalkboardTeacher,
      title: 'Support our Faculty',
      subtitle:
        'Make School hires former engineers to teach full-time at Make School, bringing real industry perspectives into the classroom. To keep our curriculum up to industry standards, we make continued investments in curriculum development and maintaining industry relationships, initiatives that will benefit from your support.',
    },
    {
      icon: FaHandHoldingHeart,
      title: 'Support our Mission',
      subtitle:
        "We are committed to creating a repeatable pathway for low-income students to join the top strata of the tech industry. This is a largely unsolved problem and little progress has been made in the last decade. Bootcamps are not a replacement for a multi-year investment in education during the formative years of a young adult's life and research shows that degrees still matter and matter most for low-income students. Join us in being part of the solution.",
    },
  ],
};
