import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    fontWeight: '700',
    color: '#0d4062',
    fontSize: '1.875rem',
    lineHeight: '2.1',
    marginBottom: '60px',
    textAlign: 'center',
  },
  card: {
    height: '100%',
    maxWidth: '660px',
  },
  cardHeader: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: '2.05',
    textAlign: 'center',
    backgroundColor: '#f5f6f8',
    color: '#454c4c',
    padding: '18px 12px',
  },
  paragraph: {
    fontWeight: '400',
    color: '#454c4c',
    fontSize: '1rem',
    lineHeight: '2',
    padding: '36px 66px',
  },
});

const CardWithHeader = props => {
  const classes = useStyles();
  const {
    card: {title, content},
  } = props;
  return (
    <Card variant="outlined" className={clsx(classes.card)}>
      <CardHeader className={clsx(classes.cardHeader)} title={<h5>{title}</h5>} />
      <CardContent>
        <p className={clsx(classes.paragraph)}>{content}</p>
      </CardContent>
    </Card>
  );
};

CardWithHeader.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default CardWithHeader;
