import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import {HeaderSection} from 'components/molecules';
import {Helmet} from 'react-helmet';
import clsx from 'clsx';

const useStyles = makeStyles(_ => ({
  titleText: {
    fontWeight: 700,
    lineHeight: 1.29,
    textAlign: 'center',
  },

  subtitle: {
    lineHeight: 1.59,
    marginBottom: 20,
  },
}));

const InstagramWidget = props => {
  const {title, subtitle, className, ...rest} = props;
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      className={clsx(classes.positionRelative, className)}
      {...rest}
    >
      <Helmet>
        <script src="https://apps.elfsight.com/p/platform.js" defer />
      </Helmet>
      <HeaderSection
        title={title}
        subtitle={
          <a href="https://www.instagram.com/make_school/?hl=en" target="_blank" rel="noreferrer">
            <Typography variant="h5" color="primary" className={classes.titleText}>
              {subtitle}
            </Typography>
          </a>
        }
      />
      <div className="elfsight-app-fc66a901-0406-44f7-8bd7-0cb512e4dcd5" />
    </Grid>
  );
};
InstagramWidget.defaultProps = {
  title: 'Follow along to see what makes us',
  subtitle: '@make_school',
};
InstagramWidget.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Title to be rendered
   */
  title: PropTypes.string,
  /**
   * Subtitle to be rendered
   */
  subtitle: PropTypes.string,
};

export default InstagramWidget;
