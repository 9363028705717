import React from 'react';
import PropType from 'prop-types';
import {
  Section,
  SectionAlternate,
  DiscoverMakeSchool,
  InstagramWidget,
  HeroCta,
} from 'components/organisms';

import {HeaderSection} from 'components/molecules';
import {GetInvolved, LifeOnCampus} from './Components';

const CampusLife = props => {
  const {data} = props;

  return (
    <div>
      <Section>
        <HeroCta data={data.heroData} description />
      </Section>
      <SectionAlternate>
        <HeaderSection {...data.joinCommunity} />
      </SectionAlternate>
      <Section>
        <LifeOnCampus data={data.lifeOnCampus} />
      </Section>
      <SectionAlternate>
        <GetInvolved data={data.getInvolved} />
      </SectionAlternate>
      <Section>
        <HeaderSection {...data.visit} />
      </Section>
      <SectionAlternate>
        <DiscoverMakeSchool />
      </SectionAlternate>
      <Section>
        <InstagramWidget />
      </Section>
    </div>
  );
};

CampusLife.propType = {
  data: PropType.object,
  openModal: PropType.func,
};

export default CampusLife;
