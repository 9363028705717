import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  videoWrapper: {
    paddingTop: '56.25%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    width: '100%',
    position: 'relative',
  },
  embed: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: '0 auto',
    width: '100%',
    height: '100%',
  },
}));

const previewImage = ({src, alt, previewImg}) => `
    <style>
      * {
        padding: 0;
        margin: 0;
        overflow: hidden;
      }

      html, body {
        height: 100%;
      }

      img, span {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      span {
        height: 1.5em;
        text-align: center;
        font: 48px/1.5 sans-serif;
        color: white;
        text-shadow: 0 0 0.5em black;
      }
    </style>
    <a href='${src}' style="width: 100%">
      <img src='${previewImg}' alt='Play YouTube video: ${alt}' />
      <span>▶&#xFE0E;</span>
    </a>
  `;

const Video = ({alt, videoId, preview}) => {
  const previewImg = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  let src = `https://www.youtube.com/embed/${videoId}?rel=0&autohide=1&showinfo=0&modestbranding=1`;
  let srcDoc = null;
  if (preview) {
    src += `&autoplay=1`;
    srcDoc = previewImage({src, alt, previewImg});
  }
  const classes = useStyles();

  return (
    <Box className={classes.videoWrapper}>
      <iframe
        className={classes.embed}
        src={src}
        srcDoc={srcDoc}
        title={alt}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </Box>
  );
};

Video.defaultProps = {
  preview: false,
};

Video.propTypes = {
  videoId: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

export default Video;
