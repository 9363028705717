import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';

const DonationInfoModal = props => {
  const {open, handleClose} = props;
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');

  const formUrl =
    'https://script.google.com/macros/s/AKfycbwMPG56aEmHa2KHIJnQThmzMwQxw0em4XHPtWpZhiZJKTe-XZcWo7LQmVjDsKBRfb92dA/exec';

  const handleNameUpdate = e => {
    setName(e.target.value);
  };

  const handleEmailUpdate = e => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    const values = {Name: name, Email: email};
    handleClose();
    axios.get(formUrl, {
      params: values,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="make-a-donation">
      <DialogTitle id="make-a-donation">Get in Touch</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please leave your name and email, we will get in touch and schedule a time to discuss.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          onChange={handleNameUpdate}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          id="Email"
          label="Email Address"
          type="email"
          onChange={handleEmailUpdate}
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DonationInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DonationInfoModal;
