import {urlWithDefaults} from 'components/atoms/CloudinaryImage/CloudinaryImage';
import {fit, fill} from '@cloudinary/base/actions/resize';

const articleOpts = img => img.resize(fill(346));
export const ArticleClimateJusticeURL = (opts = articleOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_Xkn8MD3XdromoG-4QvCZ2A', opts});
export const ArticleIndependentAccreditationURL = (opts = articleOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_KGzh2h6a2EcPOPIlpdkYUw', opts});
export const ArticleNasaDatasetURL = (opts = articleOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_XZQW8W8udFXXEZBPlgeELw', opts});
export const ArticleOnlineOptionURL = (opts = articleOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_L7lslOTFTewj7pH7yu5I1Q', opts});
export const ArticleProspectsStudentsURL = (opts = articleOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_p9noJFNKu4SleycUbA3S6g', opts});
export const ArticleRemoteTeachingURL = (opts = articleOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/0_bCzO-E0-Bdua9Bwv', opts});

const authorOpts = img => img.resize(fill(40, 40));
export const AuthorAleiaKnightURL = (opts = authorOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_iuqNon90Jjuvjr_nQaC11Q', opts});
export const AuthorAshuDesaiURL = (opts = authorOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_gOdlg04xEWg4dO6RMYccIw', opts});
export const AuthorDanMorseURL = (opts = authorOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_kzn6qSledRSAuzqUo-4koQ', opts});
export const AuthorKomalDesaiURL = (opts = authorOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/0_SJtqq_G3C1zSyX05', opts});
export const AuthorStephanieCURL = (opts = authorOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/2_VLbyqd2EduzRDVF1TLt4-Q', opts});
export const AuthorZainRazaURL = (opts = authorOpts) =>
  urlWithDefaults({publicId: 'Homepage/articles/1_Th1nZ-_ULj3KSEbVQPNRJA', opts});

const companyOpts = img => img.resize(fit(200, 100));
export const CompanyLogoBloombergURL = (opts = companyOpts) =>
  urlWithDefaults({publicId: 'Homepage/companies/industry_collab/bloomberg', opts});
export const CompanyLogoGoogleURL = (opts = companyOpts) =>
  urlWithDefaults({publicId: 'Homepage/companies/industry_collab/google-icon', opts});
export const CompanyLogoLyftURL = (opts = companyOpts) =>
  urlWithDefaults({
    publicId: 'Homepage/companies/lyft',
    opts,
  });
export const CompanyLogoMicrosoftURL = (opts = companyOpts) =>
  urlWithDefaults({
    publicId: 'Homepage/companies/industry_collab/Microsoft-Logo-icon-png-Transparent-Background',
    opts,
  });
export const CompanyLogoShopifyURL = (opts = companyOpts) =>
  urlWithDefaults({publicId: 'Homepage/companies/industry_collab/shopify-logo', opts});
export const CompanyLogoSpotifyURL = (opts = companyOpts) =>
  urlWithDefaults({publicId: 'Homepage/companies/spotify', opts});
export const CompanyLogoStravaURL = (opts = companyOpts) =>
  urlWithDefaults({publicId: 'Homepage/companies/industry_collab/323_Strava_logo-512', opts});
export const CompanyLogoYelpURL = (opts = companyOpts) =>
  urlWithDefaults({
    publicId: 'Homepage/companies/industry_collab/yelp-1-logo-png-transparent',
    opts,
  });

export const HomepageHeroCroppedURL = (opts = img => img.resize(fit().height(656))) =>
  urlWithDefaults({publicId: 'Homepage/Hero_Image_Cropped', opts});
export const GivingHeroCroppedURL = (opts = img => img.resize(fit().height(656))) =>
  urlWithDefaults({publicId: 'Homepage/hero cards/Fall_application_ticker_image_resized', opts});

export const housingOpts = img => img.resize(fill(500));
export const HousingGameHallURL = (opts = housingOpts) =>
  urlWithDefaults({publicId: 'ProductCollege/housing/housing_5', opts});
export const HousingGroupActivitiesURL = (opts = housingOpts) =>
  urlWithDefaults({publicId: 'ProductCollege/housing/housing_1', opts});
export const HousingPeopleDiscussURL = (opts = housingOpts) =>
  urlWithDefaults({publicId: 'ProductCollege/housing/housing_6', opts});
export const HousingPlayingMusicURL = (opts = housingOpts) =>
  urlWithDefaults({publicId: 'ProductCollege/housing/housing_4', opts});
export const HousingReadingURL = (opts = housingOpts) =>
  urlWithDefaults({publicId: 'ProductCollege/housing/housing_2', opts});
export const HousingViewsURL = (opts = housingOpts) =>
  urlWithDefaults({publicId: 'ProductCollege/housing/housing_3', opts});

const msLogoOpts = img => img.resize(fit().width(120));
export const MSLogoDarkBgURL = (opts = msLogoOpts) =>
  urlWithDefaults({publicId: 'logo_white', opts});
export const MSLogoLightBgURL = (opts = msLogoOpts) =>
  urlWithDefaults({publicId: 'BrandGuidelines/logo_white_background', opts});
export const MSLogoURL = (darkMode = false, opts = msLogoOpts) =>
  darkMode ? MSLogoDarkBgURL(opts) : MSLogoLightBgURL(opts);
export const MSLogoIconURL = (opts = img => img.resize(fit().height(32))) =>
  urlWithDefaults({publicId: 'BrandGuidelines/logo_icon', opts});

const navigationIconOpts = img => img.resize(fit().height(48));
export const NavigationIconAcademicCalendarURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/acad-calendar', opts});
export const NavigationIconAcademicsURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/academics', opts});
export const NavigationIconAdmissionsURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/admissions', opts});
export const NavigationIconCareerSupportURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/career-support', opts});
export const NavigationIconCommunityURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/community', opts});
export const NavigationIconCourseCatalogURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/course-catalogue', opts});
export const NavigationIconFaqsURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/faqs', opts});
export const NavigationIconParentsURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/parents', opts});
export const NavigationIconStudentLifeURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/campus-life', opts});
export const NavigationIconTuitionURL = (opts = navigationIconOpts) =>
  urlWithDefaults({publicId: 'Icons/tuition', opts});

const studentFeatureOpts = img => img.resize(fill(192, 192));
export const StudentFeatureSalomonCohenURL = (opts = studentFeatureOpts) =>
  urlWithDefaults({publicId: 'Students/Salo_Cohen', opts});
export const StudentFeatureYinChangURL = (opts = studentFeatureOpts) =>
  urlWithDefaults({publicId: 'Students/Yin_Chang', opts});

const profileOpts = img => img.resize(fill(102, 102));
export const ProfileAdamBrausURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Braus_Adam', opts});
export const ProfileAdrianaGonzalezURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Gonzalez_Adriana', opts});
export const ProfileAnneSpaldingURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Spalding_Anne', opts});
export const ProfileDaniRoxberryURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Roxberry_Dani', opts});
export const ProfileDanMorseURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Morse_Dan', opts});
export const ProfileLisaTranURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Tran_Lisa', opts});
export const ProfileMeganDiasURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Dias_Megan', opts});
export const ProfileMeredithMurphyURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Murphy_Meredith', opts});
export const ProfileMichelleBlackburnURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Blackburn_Michelle', opts});
export const ProfileMitchellHudsonURL = (opts = profileOpts) =>
  urlWithDefaults({publicId: 'Faculty/Mitchell', opts});

export const TeamPhotoHomepageURL = (opts = img => img.resize(fit(532))) =>
  urlWithDefaults({publicId: 'Homepage/Team_Photo_Homepage', opts});
