import React from 'react';
import {SlateFormModal} from 'components/organisms';
import {HeaderSection} from 'components/molecules';
import clsx from 'clsx';
import {Button} from 'gatsby-theme-material-ui';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    textDecoration: 'none',
  },
  description: {
    color: '#718096',
  },
}));

const HeroCta = props => {
  const {
    data: {title, subtitle, paragraph},
    align,
    gridSize,
    description,
  } = props;
  /**
   *
   * Modal handling
   */
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState(0);

  const handleOpen = location => {
    setOpen(true);
    setScroll(window.scrollY);
    if (window.analytics) {
      window.analytics.track('Info - Form - Open', {
        ctaLocation: `${window.location.pathname}-${location}`,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    window.scrollTo(0, scroll + 1);
  };
  /** ****************************** */
  const classes = useStyles();
  return (
    <>
      <div className={clsx(classes.root)}>
        <HeaderSection
          title={title}
          titleVariant="h2"
          ctaGroup={[
            <Button
              size="large"
              color="default"
              variant="contained"
              target="_blank"
              rel="noreferrer"
              className={clsx(classes.link)}
              href={process.env.GATSBY_MS_APPLY_LINK}
            >
              START APPLYING
            </Button>,
            <Button onClick={handleOpen} size="large" color="primary" variant="contained">
              REQUEST INFO
            </Button>,
          ]}
        />
        <HeaderSection
          title={subtitle}
          subtitle={!description && paragraph}
          subtitleProps={{
            align: align || 'center',
          }}
          description={description && paragraph}
          gridSize={gridSize}
          topMargin
          disableGutter
        />
      </div>
      <SlateFormModal
        open={open}
        handleClose={handleClose}
        formId="c8e778a6-d6f8-4deb-9b17-14e36de220b8"
      />
    </>
  );
};

HeroCta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    paragraph: PropTypes.string,
  }),
  gridSize: PropTypes.string,
};

export default HeroCta;
