import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(_ => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '184px',
    margin: '1em',
  },
  title: {
    fontWeight: '700',
    color: '#555',
    fontSize: '1rem',
    letterSpacing: '1.2px',
    lineHeight: '2.17',
    textAlign: 'center',
  },
}));

const IconWithTitle = props => {
  const classes = useStyles();
  const {icon, content} = props;
  return (
    <div className={clsx(classes.container)}>
      <img src={icon} alt={`Icon for "${content}"`} />
      <span className={clsx(classes.title)}>{content}</span>
    </div>
  );
};

IconWithTitle.propTypes = {
  /**
   * Icon image url
   */
  icon: PropTypes.string,
  /**
   * Text to be displayed under the icon
   */
  content: PropTypes.string,
};

export default IconWithTitle;
