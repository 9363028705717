import React from 'react';
import {Link} from 'gatsby-theme-material-ui';

import {TeamPhotoHomepageURL} from 'components/atoms/CloudinaryImage';
import microsoft from 'assets/images/accelerated-degree/microsoftLogo.svg';
import apple from 'assets/images/accelerated-degree/appleLogo.svg';
import nasa from 'assets/images/accelerated-degree/nasaLogo.svg';
import twitch from 'assets/images/accelerated-degree/twitchLogo.svg';
import facebook from 'assets/images/accelerated-degree/facebookLogo.svg';
import tesla from 'assets/images/accelerated-degree/teslaLogo.svg';

export const hero = {
  title: 'Make School.org',
  subtitle:
    'Creating avenues of upward mobility for students of all backgrounds, and empowering them to contribute to society through science and technology innovation.',
  trustedBy: [
    {
      logo: microsoft,
      name: 'Microsoft',
    },
    {
      logo: apple,
      name: 'Apple',
    },
    {
      logo: nasa,
      name: 'NASA',
    },
    {
      logo: twitch,
      name: 'Twitch',
    },
    {
      logo: tesla,
      name: 'Tesla',
    },
    {
      logo: facebook,
      name: 'Facebook',
    },
  ],
};

export const aboutMS = {
  text: (
    <>
      <p>
        Make School.org is a non-profit organization dedicated to creating avenues of upward
        mobility for students of all backgrounds, empowering them to contribute to society through
        science and technology innovation.
      </p>
      <p>
        Most recently Make School.org fulfilled this mission by serving a majority under-resourced
        and underrepresented student population through a Bachelor’s degree program, countering
        systemic barriers to upward mobility and preparing them for high-earning careers. The
        Bachelor’s in Applied Computer Science (BACS) program provided a two year, project based,
        career focused degree program for hundreds of students. Graduates from the program are
        quickly employed by top tier tech companies making starting salaries averaging over $100k
        annually.
      </p>
      <p>
        As of August 2021, Make School.org will no longer be running the Applied Computer Science
        program as it will now be run out of Dominican University of California. To learn more,
        visit the{' '}
        <Link href="https://www.dominican.edu/academics/undergraduate-programs/applied-computer-science">
          Dominican ACS Website
        </Link>
        .
      </p>
    </>
  ),
  image: TeamPhotoHomepageURL(),
};

export const ourMission = {
  text: (
    <>
      <p>
        Make School.org continues to support students from underrepresented backgrounds through our
        scholarship program. Funds donated will be used to provide tuition support for students who
        were part of the BACS cohort while it was hosted at Make School. To give to Make School,
        visit our <Link href={process.env.GATSBY_MS_DONATION_LINK}>Donate page</Link>.
      </p>
      <p>
        Make School.org will continue to explore ways to fulfill this mission in the future. If you
        would like to connect or learn more about the Make School story, please contact us at{' '}
        <Link href="mailto:admin@makeschool.org">admin@makeschool.org</Link>
      </p>
    </>
  ),
  image: 'https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg',
};
