import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {Paper} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import getTheme from 'theme';
import {PageContextProvider} from 'data/page-context';
import {SEO} from 'components/atoms';

export default function WithLayout({component: Component, layout: Layout, pageContext, ...rest}) {
  const themeMode = 'light';
  const theme = getTheme(themeMode);
  return (
    <PageContextProvider pageContext={pageContext}>
      <SEO>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Paper elevation={0}>
            <Layout {...rest} themeMode={themeMode}>
              <Component pageContext={pageContext} {...rest} />
            </Layout>
          </Paper>
        </ThemeProvider>
      </SEO>
    </PageContextProvider>
  );
}
