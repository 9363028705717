import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Avatar, Typography} from '@material-ui/core';
import {Image} from 'components/atoms';
import {HeaderSection} from 'components/molecules';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 70,
    height: 70,
    borderRadius: 0,
    background: 'transparent',
  },
  textWhite: {
    color: 'white',
  },
  listAvatar: {
    width: 100,
    height: 100,
    borderRadius: theme.spacing(1),
  },
  listItemAvatar: {
    marginRight: theme.spacing(3),
  },
  titleBottomMargin: {
    marginBottom: theme.spacing(1),
  },
  gridCard: {
    padding: theme.spacing(2),
    background: theme.palette.background.level2,
    borderRadius: '4px',
    minHeight: '285px',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  gridItem: {
    height: '100%',
  },
}));

const Employers = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  const title = data.topOutComesTagline ?? (
    <span>
      A New{' '}
      <Typography component="span" variant="inherit" color="primary">
        University
      </Typography>
      <br />
      is Born.
    </span>
  );

  const subtitle =
    data.topOutcomesSummary ??
    `Make School is pursuing independent accreditation to become the first new university purpose-built to prepare underrepresented talent for top tech careers.`;

  return (
    <div className={className} data-aos="fade-up" {...rest}>
      <HeaderSection
        title={title}
        subtitle={subtitle}
        fadeUp
        className={classes.titleBottomMargin}
      />
      <Grid container spacing={1} direction="row" justify="center" alignItems="center">
        {data.logos.map(item => (
          <Grid
            key={item.name}
            item
            container
            alignItems="center"
            direction="column"
            xs={4}
            sm={3}
            md={2}
            data-aos="fade-up"
          >
            <Avatar className={classes.avatar}>
              <Image src={item.logo} alt={item.name} ratio={item.aspectRatio} />
            </Avatar>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Employers.propTypes = {
  openModal: PropTypes.func,
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default Employers;
