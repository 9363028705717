import {Helmet} from 'react-helmet';
import React from 'react';
import {defaultTitle, defaultOgTitle, defaultOgImage, defaultOgDescription} from 'locales/en';
import {usePageContext} from 'data/page-context';

const contextToSeo = pageContext => {
  return {
    title: pageContext?.title || defaultTitle,
    socialTitle: pageContext?.socialTitle || pageContext?.title || defaultOgTitle,
    description: pageContext?.description || defaultOgDescription,
    image: pageContext?.image || defaultOgImage,
    twitterUsername: 'MakeSchool',
    url:
      pageContext?.location?.pathname &&
      `https://${process.env.GATSBY_DOMAIN_ROOT}${pageContext?.location?.pathname}`,
  };
};

const SEO = incoming => {
  const {children} = incoming;
  const pageContext = usePageContext();
  const {title, description, image, url, twitterUsername} = contextToSeo(pageContext);
  // const noScriptText = `${defaultOgDescription} Try enabling Javascript to view this full page!`;
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title} | Make School</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta name="twitter:title" content={`${title} | Make School`} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        <meta property="og:title" content={`${title} | Make School`} />
        <meta property="og:description" content={description} />
        {url && <meta property="og:url" content={url} />}
        {/* <noscript>{noScriptText}</noscript> */}
      </Helmet>
      {children}
    </>
  );
};

export default SEO;
