import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Link} from 'gatsby-theme-material-ui';
import {HeaderSection} from 'components/molecules';
import discoverData from './data';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
    fontSize: '1.875rem',
    lineHeight: '2.1',
    marginBottom: '60px',
    textAlign: 'center',
    color: '#0d4062',
  },
  iconsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '60px auto 0',
    maxWidth: '767px',
  },
  icon: {
    maxHeight: '48px',
    marginRight: '30px',
  },
  slotWrapper: {
    display: 'flex',
    margin: '0 2.5% 60px',
    alignItems: 'center',
    minWidth: '45%',
  },
  span: {
    color: '#12739e',
    marginRight: '15px',
  },
  arrow: {
    transition: 'all 150ms ease-in',
    '&:hover': {
      marginLeft: '15px',
    },
  },
}));

const DiscoverMakeSchool = props => {
  const {
    data: {title, icons},
  } = props;
  const classes = useStyles();
  return (
    <>
      <HeaderSection title={title} />
      <div className={classes.iconsWrapper}>
        {icons.map(el => (
          <Link to={el.to} className={clsx(classes.slotWrapper)}>
            <img alt={el.content} className={clsx(classes.icon)} src={el.icon} />
            <span className={clsx(classes.span)}>{el.content}</span>
            <ArrowForwardIcon className={clsx(classes.arrow)} style={{color: '#12739e'}} />
          </Link>
        ))}
      </div>
    </>
  );
};

DiscoverMakeSchool.defaultProps = {
  data: discoverData,
};

DiscoverMakeSchool.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    icons: PropTypes.arrayOf(
      PropTypes.shape({
        // Icon image url
        icon: PropTypes.string,
        content: PropTypes.string,
        to: PropTypes.string,
      })
    ),
  }),
};

export default DiscoverMakeSchool;
