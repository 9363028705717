import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: theme.spacing(1),
  },
  listItemAvatar: {
    marginRight: theme.spacing(3),
  },
  gridCard: {
    padding: theme.spacing(2),
    background: theme.palette.alternate.main,
    borderRadius: theme.spacing(2),
    minHeight: '320px',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  gridItem: {
    height: '100%',
  },
}));

const SocialProof = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container align="center" justify="center" alignItems="center" spacing={4}>
        {data.socialProof.map(quote => (
          <Grid item xs={12} md={6} key={quote.authorName} className={classes.gridItem}>
            <Grid container className={classes.gridCard} spacing={2}>
              <Grid
                item
                container
                justify={isMd ? 'flex-start' : 'center'}
                alignItems="center"
                xs={12}
              >
                <List disablePadding>
                  <ListItem disableGutters>
                    <ListItemAvatar className={classes.listItemAvatar}>
                      <Img
                        fixed={data[quote.authorPhoto].childImageSharp.fixed}
                        alt={quote.authorName}
                        className={classes.avatar}
                        loading="lazy"
                      />
                    </ListItemAvatar>
                    <ListItemText primary={quote.authorName} secondary={quote.authorOccupation} />
                  </ListItem>
                </List>
              </Grid>
              <Grid
                item
                container
                justify="space-between"
                alignItems={isMd ? 'flex-start' : 'center'}
                xs={12}
              >
                <Typography variant="body1" color="textPrimary" align={isMd ? 'left' : 'center'}>
                  "{quote.feedback}"
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

SocialProof.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default SocialProof;
