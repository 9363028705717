import React from 'react';
import {Cloudinary} from '@cloudinary/base';
import {format, quality} from '@cloudinary/base/actions/delivery';
import {auto} from '@cloudinary/base/qualifiers/format';
import {auto as qAuto} from '@cloudinary/base/qualifiers/quality';
import {fit} from '@cloudinary/base/actions/resize';
import {AdvancedImage, accessibility, responsive, lazyload} from '@cloudinary/react';

const cld = new Cloudinary({
  cloud: {
    cloudName: 'makeschool',
  },
  url: {
    secure: true,
  },
});

export const imgWithDefaults = ({publicId, opts = img => img}) => {
  return opts(cld.image(publicId).delivery(format(auto())).delivery(quality(qAuto())));
};

export const urlWithDefaults = ({publicId, opts = img => img.resize(fit().width(846))}) =>
  imgWithDefaults({publicId, opts}).toURL();

const CloudinaryImage = ({publicId, opts = img => img}) => (
  <AdvancedImage
    cldImg={imgWithDefaults({publicId, opts})}
    plugins={[responsive(), accessibility(), lazyload()]}
  />
);

export default CloudinaryImage;
