import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'gatsby-theme-material-ui';
import {Typography, IconButton} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import RedditIcon from '@material-ui/icons/Reddit';
import {FaGooglePlusG} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  socialIcon: {
    borderRadius: 0,
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    background: theme.palette.alternate.main,
    '&:last-child': {
      marginRight: 0,
    },
  },
  NewsItem__Content: {
    '& *': {
      color: '#454c4c',
      fontSize: '1.125rem',
      lineHeight: '2.2',
    },
    '& h2': {
      fontSize: '1.5rem',
    },

    '& h3': {
      fontSize: '1.25rem',
    },

    '& a': {
      fontSize: '1.125rem',
    },
    '& img': {
      borderRadius: '10px',
      maxWidth: '100%',
    },

    '& iframe': {
      maxWidth: '100%',
    },

    '& ul': {
      paddingLeft: '1em',

      '& ul': {
        margin: '0',
      },
      '& ol': {
        margin: '0',
      },
    },
    '& ol': {
      paddingLeft: '1em',

      '& ul': {
        margin: '0',
      },
      '& ol': {
        margin: '0',
      },
    },

    '& blockquote': {
      padding: '0 5%',
      color: '#87898c',
      '& p': {
        fontSize: '1.5rem',
        lineHeight: '1.6',
      },
      '& span': {
        fontSize: '1.5rem',
        lineHeight: '1.6',
      },
      '& em': {
        fontSize: '1.5rem',
        lineHeight: '1.6',
      },
      '& strong': {
        color: '#87898c',
        fontSize: '1.5rem',
        lineHeight: '1.6',
      },
    },

    '& hr': {
      border: '0',
      height: '1px',
      backgroundImage:
        'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))',
    },
  },
}));

const Content = props => {
  const {data, ...rest} = props;
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const classes = useStyles();

  return (
    <div {...rest}>
      <div className={classes.section}>
        <Typography color="textPrimary">
          {/* eslint-disable-next-line react/no-danger */}
          <div
            className={classes.NewsItem__Content}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{__html: data.content}}
          />
        </Typography>
      </div>
      <div>
        <IconButton className={classes.socialIcon}>
          <Link
            target="_blank"
            rel="noreferrer"
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${data.title}`}
          >
            <FacebookIcon />
          </Link>
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <Link
            target="_blank"
            rel="noreferrer"
            href={`https://twitter.com/intent/tweet?url=${url}&text=${data.title}&via=makeschool`}
          >
            <TwitterIcon />
          </Link>
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <Link target="_blank" rel="noreferrer" href={`https://plus.google.com/share?url=${url}`}>
            <FaGooglePlusG />
          </Link>
        </IconButton>
        <IconButton className={classes.socialIcon}>
          <Link
            target="_blank"
            rel="noreferrer"
            href={`http://www.reddit.com/submit?url=${url}&title=${data.title}`}
          >
            <RedditIcon />
          </Link>
        </IconButton>
      </div>
    </div>
  );
};

Content.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default Content;
