import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Typography} from '@material-ui/core';
import {Button} from 'gatsby-theme-material-ui';
import {Image} from 'components/atoms';
import {Section} from 'components/organisms';
import wantToWork from 'assets/images/illustrations/want-to-work.svg';
import workingOnSofa from 'assets/images/illustrations/working-on-sofa.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.alternate.main,
  },
  sectionNoPadding: {
    padding: theme.spacing(6, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6, 8),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 8),
    },
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 900,
  },
  image: {
    maxHeight: 150,
  },
  wideImage: {
    paddingRight: theme.spacing(2),
    maxHeight: 130,
  },
  gridCard: {
    padding: theme.spacing(2),
    background: theme.palette.alternate.main,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  subtitle: {
    margin: theme.spacing(2, 0),
  },
  link: {
    color: 'inherit',
  },
}));

const SplitCTA = props => {
  const {className, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section className={classes.sectionNoPadding}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Grid container className={classes.gridCard} spacing={2}>
              <Grid
                item
                container
                justify={isMd ? 'flex-start' : 'center'}
                alignItems="center"
                xs={12}
                md={6}
              >
                <Image src={workingOnSofa} className={classes.image} />
              </Grid>
              <Grid
                item
                container
                justify="space-between"
                alignItems={isMd ? 'flex-start' : 'center'}
                xs={12}
                md={6}
                direction="column"
              >
                <Typography
                  variant="h6"
                  className={classes.title}
                  color="textPrimary"
                  align={isMd ? 'left' : 'center'}
                >
                  Apply to our Bachelor’s program for fall 2021
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  align={isMd ? 'left' : 'center'}
                  className={classes.subtitle}
                >
                  Applying is free and takes just 1 hour. You’ll hear back in 2 weeks.
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.GATSBY_MS_APPLY_LINK}
                >
                  Apply Now
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container className={classes.gridCard} spacing={2}>
              <Grid
                item
                container
                justify={isMd ? 'flex-start' : 'center'}
                alignItems="center"
                xs={12}
                md={6}
              >
                <Image src={wantToWork} className={classes.wideImage} />
              </Grid>
              <Grid
                item
                container
                justify="space-between"
                alignItems={isMd ? 'flex-start' : 'center'}
                xs={12}
                md={6}
                direction="column"
              >
                <Typography
                  variant="h6"
                  className={classes.title}
                  color="textPrimary"
                  align={isMd ? 'left' : 'center'}
                >
                  Try a Make School course for free online.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  align={isMd ? 'left' : 'center'}
                  className={classes.subtitle}
                >
                  Explore Computer Science through our free online courses
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  target="_blank"
                  href={process.env.GATSBY_MS_START_LEARNING_LINK}
                >
                  Start Learning
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    </div>
  );
};

SplitCTA.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default SplitCTA;
