import React from 'react';
import {Divider, useMediaQuery, Grid, Typography} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';

import {Section, SectionAlternate} from 'components/organisms';
import {Hero, ParagraphImage} from 'components/sections';
import {hero, aboutMS, ourMission} from './data';
import {Image} from '../../components/atoms';

const useStyles = makeStyles(_ => ({
  employers: {
    fontWeight: 900,
  },
}));

const Home = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const heroFooter = (
    <>
      <Typography
        className={classes.employers}
        variant="h6"
        color="primary"
        align={isMd ? 'left' : 'center'}
        gutterBottom
      >
        Trusted By:
      </Typography>
      <Grid container justify="space-between" spacing={4}>
        {hero.trustedBy.map((partner, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Grid item container justify="center" xs={6} sm={2} key={index}>
            <Image
              src={partner.logo}
              alt={partner.name}
              className={classes.promoLogo}
              lazy={false}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
  return (
    <div>
      <Hero title={hero.title} subtitle={hero.subtitle} image="hero" footer={heroFooter} />
      <Divider />
      <SectionAlternate>
        <ParagraphImage data={aboutMS} />
      </SectionAlternate>
      <Section>
        <ParagraphImage data={ourMission} swapped />
      </Section>
    </div>
  );
};

export default Home;
