/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, Typography, ListItemIcon, Divider} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {LoginStateCTA} from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  closeIcon: {
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    marginRight: theme.spacing(8),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
  },
}));

const SidebarNav = props => {
  const {pages, onClose, className, ...rest} = props;
  const classes = useStyles();

  const {bachelors, admissions, tuition, login} = pages;

  const MenuGroup = mgProps => {
    const {item} = mgProps;
    return (
      <List disablePadding>
        <ListItem disableGutters>
          <Typography variant="body2" color="primary" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body1"
              component="a"
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
              color="textSecondary"
              onClick={() => onClose()}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const BachelorsPages = () => {
    const {school, academics, community, outcomes} = bachelors.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={school} />
          <MenuGroup item={academics} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={community} />
          <MenuGroup item={outcomes} />
        </div>
      </div>
    );
  };

  const AdmissionsPages = () => {
    const {admissions: admissionsGroup} = admissions.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={admissionsGroup} />
        </div>
      </div>
    );
  };

  const TuitionPages = () => {
    const {tuition: tuitionGroup, aid} = tuition.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={tuitionGroup} />
          <MenuGroup item={aid} />
        </div>
      </div>
    );
  };

  const LoginMorePages = () => {
    const {login: loginGroup, mediabook, makeSchool} = login.children;
    return (
      <div className={classes.menu}>
        <div className={classes.menuItem}>
          <MenuGroup item={loginGroup} />
          <MenuGroup item={mediabook} />
        </div>
        <div className={classes.menuItem}>
          <MenuGroup item={makeSchool} />
        </div>
      </div>
    );
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.closeIcon} onClick={() => onClose()}>
        <LoginStateCTA />
        <ListItemIcon className={classes.listItemIcon}>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Bachelor's in CS
        </Typography>
        <BachelorsPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Admissions
        </Typography>
        <AdmissionsPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Divider className={classes.divider} />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Tuition & Aid
        </Typography>
        <TuitionPages />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Login & More
        </Typography>
        <LoginMorePages />
      </ListItem>
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

export default SidebarNav;
