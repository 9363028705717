import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Divider, Typography} from '@material-ui/core';
import {Section, SectionAlternate} from 'components/organisms';
import {Hero} from 'components/sections';
import {Button} from 'gatsby-theme-material-ui';
import {HeaderSection} from 'components/molecules';
import {Employers} from './components';

import {employers} from './data';

const useStyles = makeStyles(theme => ({
  paddingTop: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
}));

const Giving = ({openModal}) => {
  const classes = useStyles();
  const ctaGroup = [
    <Button variant="outlined" color="primary" size="large" onClick={openModal}>
      Get in Touch to Donate
    </Button>,
  ];

  return (
    <div>
      <Hero
        title={
          <span>
            Our New{' '}
            <Typography
              component="span"
              variant="inherit"
              color="primary"
              style={{display: 'inline-block'}}
            >
              Scholarship Program
            </Typography>
          </span>
        }
        subtitle="Make School continues to support students from underrepresented backgrounds through our scholarship program. Funds donated will be used to provide tuition support for students who were part of the BACS cohort while it was hosted at Make School."
        image="hero"
        footer={<HeaderSection ctaGroup={ctaGroup} align="left" />}
      />
      <Section className={classes.paddingTop}>
        <Employers data={{...employers}} />
      </Section>
      <Divider />
      <SectionAlternate id="how-you-can-help">
        <HeaderSection
          title={
            <span>
              How{' '}
              <Typography color="primary" variant="inherit" component="span">
                you
              </Typography>{' '}
              can help
            </span>
          }
          subtitle="Support Make School’s next chapter as a mission-driven 501c(3) non-profit organization."
          ctaGroup={ctaGroup}
          disableGutter
        />
      </SectionAlternate>
    </div>
  );
};

export default Giving;
