import React from 'react';
import clsx from 'clsx';
import {Button, Link} from 'gatsby-theme-material-ui';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Grid, Typography, useMediaQuery} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  disableGutter: {
    marginBottom: 0,
  },
  title: {
    fontWeight: 'bold',
  },
  bodyText: {
    fontWeight: 400,
    lineHeight: 2,
    '& ul': {
      marginLeft: theme.spacing(6),
    },
    '& ol': {
      marginLeft: theme.spacing(6),
    },
    '& p': {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(2),
      },
    },
  },
  descriptionHolder: {
    margin: 'auto',
  },
  marginTop: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  cta: {
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: theme.spacing(0),
    },
  },
  button: {
    marginRight: '19px',
  },
}));

/**
 * Component to display the section headers
 *
 * @param {Object} props
 */
const HeaderSection = props => {
  const {
    title,
    titleVariant,
    subtitleVariant,
    subtitle,
    subtitleColor,
    label,
    overline,
    align,
    ctaGroup,
    disableGutter,
    titleClasses,
    className,
    labelProps,
    titleProps,
    subtitleProps,
    direction,
    description,
    descriptionVariant,
    descriptionProps,
    descriptionClassName,
    buttons,
    gridSize,
    titleGridSize,
    descriptionGridSize,
    fullWidth,
    topMargin,
    ...rest
  } = props;

  const classes = useStyles();
  let justifyGrid = 'center';
  if (align === 'left') {
    justifyGrid = 'flex-start';
  } else if (align === 'right') {
    justifyGrid = 'flex-end';
  }
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <Grid
      container
      alignItems="center"
      className={clsx(
        'section-header',
        topMargin ? classes.marginTop : {},
        disableGutter ? classes.disableGutter : classes.root,
        className
      )}
      {...rest}
    >
      {overline && (
        <Grid
          item
          container
          justify={justifyGrid}
          xs={12}
          className={clsx(classes.descriptionHolder, 'section-header__overline-wrapper')}
        >
          {overline}
        </Grid>
      )}
      {label && (
        <Grid
          item
          xs={9}
          className={clsx(classes.descriptionHolder, 'section-header__label-wrapper')}
        >
          <Typography
            variant="overline"
            color="primary"
            component="p"
            align={align || 'center'}
            {...labelProps}
          >
            {label}
          </Typography>
        </Grid>
      )}
      {title && (
        <Grid
          item
          xs={fullWidth ? 12 : titleGridSize}
          className={clsx(classes.descriptionHolder, 'section-header__title-wrapper')}
        >
          <Typography
            variant={titleVariant}
            align={align || 'center'}
            className={clsx('section-header__title', classes.title, titleClasses || {})}
            color="textPrimary"
            {...titleProps}
          >
            {title}
          </Typography>
        </Grid>
      )}
      {subtitle && (
        <Grid
          item
          xs={fullWidth ? 12 : gridSize}
          className={clsx(
            classes.descriptionHolder,
            topMargin ? {} : classes.marginTop,
            'section-header__subtitle-wrapper'
          )}
        >
          <Typography
            variant={subtitleVariant}
            align={align || 'center'}
            color={subtitleColor || 'textSecondary'}
            className="section-header__subtitle"
            style={{whiteSpace: 'pre-line'}}
            {...subtitleProps}
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
      {description && (
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          md={fullWidth ? 12 : descriptionGridSize}
          justify={justifyGrid}
          className={clsx(classes.descriptionHolder, classes.marginTop, descriptionClassName)}
        >
          <Typography
            align="left"
            variant={descriptionVariant}
            className={clsx(classes.bodyText, descriptionClassName)}
            {...descriptionProps}
          >
            {description}
          </Typography>
        </Grid>
      )}
      {ctaGroup && ctaGroup.length && (
        <Grid item xs={12} className={clsx(classes.marginTop, 'section-header__cta-wrapper')}>
          <Grid
            container
            direction={direction}
            justify={justifyGrid}
            alignItems="center"
            wrap="nowrap"
            className="section-header__cta-container"
          >
            {ctaGroup.map((item, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <div key={index} className={clsx('section-header__cta-item-wrapper', classes.cta)}>
                {item}
              </div>
            ))}
          </Grid>
        </Grid>
      )}
      {buttons && (
        <Grid item xs={12} className={clsx(classes.marginTop, 'section-header__cta-wrapper')}>
          <Grid
            container
            direction={direction}
            justify={justifyGrid}
            alignItems="center"
            wrap="nowrap"
            className="section-header__cta-container"
          >
            {buttons.map(button => (
              <Button
                className={classes.button}
                size={isMd ? 'large' : 'medium'}
                variant={button.variant}
                color={button.color}
              >
                <Link
                  className={button.variant === 'contained' && classes.whiteText}
                  to={button.to && button.to}
                  href={button.href && button.href}
                >
                  {button.text}
                </Link>
              </Button>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

HeaderSection.defaultProps = {
  titleVariant: 'h4',
  subtitleVariant: 'h5',
  labelProps: {},
  titleProps: {},
  subtitleProps: {},
  descriptionVariant: 'body1',
  descriptionProps: {},
  gridSize: 10,
  titleGridSize: 10,
  descriptionGridSize: 9,
  disableGutter: true,
};

HeaderSection.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Title of the section header
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Subtitle of the section header
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Label title of the section header
   */
  label: PropTypes.string,
  /**
   * The overline component in the section header
   */
  overline: PropTypes.node,
  /**
   * Array of the CTAs
   */
  ctaGroup: PropTypes.arrayOf(PropTypes.node),
  /**
   * Header content (title, subtitle, CTAs) alignment
   */
  align: PropTypes.oneOf(['right', 'left', 'center']),
  /**
   * Whether to disable bottom margin of the section
   */
  disableGutter: PropTypes.bool,
  /**
   * Whether to enable top margin of the section
   */
  topMargin: PropTypes.bool,
  /**
   * Styles classes to be attached to the title from the parent component
   */
  titleClasses: PropTypes.string,
  /**
   * Title variant
   */
  titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  /**
   * SubTitle variant
   */
  subtitleVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
  ]),
  /**
   * SubTitle color
   */
  subtitleColor: PropTypes.oneOf(['textPrimary', 'textSecondary', 'primary', 'secondary']),
  /**
   * Additional properties to pass to the label Typography component
   */
  labelProps: PropTypes.object,
  /**
   * Additional properties to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional properties to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,

  /**
   * Additional properties to pass for the CTA buttons
   */
  direction: PropTypes.string,
  /**
   * description to be rendered
   */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * description variant
   */
  descriptionVariant: PropTypes.string,
  /**
   * Additional properties to pass to the description Typography component
   */
  descriptionProps: PropTypes.object,
  /**
   * description className
   */
  descriptionClassName: PropTypes.string,
  /**
   * grid size
   */
  gridSize: PropTypes.number,
  titleGridSize: PropTypes.number,
  descriptionGridSize: PropTypes.number,
  fullWidth: PropTypes.bool,
};

export default HeaderSection;
