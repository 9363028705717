import React from 'react';
import PropType from 'prop-types';
import {HeaderSection} from 'components/molecules';
import BasicTable from 'components/atoms/BasicTable';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  withMarginBottom: {
    marginBottom: theme.spacing(7),
  },
  withMarginTop: {
    marginTop: theme.spacing(7),
  },
}));
const TableWithTitle = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Grid className={className} {...rest}>
      {data.title && <HeaderSection {...data.title} className={classes.withMarginBottom} />}
      {data.tables.map(el => (
        <BasicTable data={el} className={classes.withMarginTop} />
      ))}
    </Grid>
  );
};
TableWithTitle.prototype = {
  className: PropType.string,
  data: PropType.object,
};
export default TableWithTitle;
