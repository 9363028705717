import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Grid, List, ListItem} from '@material-ui/core';
import {Image} from 'components/atoms';
import LogoDark from 'assets/images/logos/logo-white.svg';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
    },
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 120,
    height: 32,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 36,
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'grid',
    gridTemplateColumns: '33% 33% 33% ',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  menuItem: {
    alignSelf: 'flex-end',
  },
  menuGroupItem: {
    fontFamily: `LucidaSans-Typewriter`,
    lineHeight: 2.71,
    paddingTop: 0,
    marginTop: 5,
    marginBottom: 10,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white',
    lineHeight: 1,
    letterSpacing: '0.47px',
    marginBottom: 10,
  },
  nonprofitDisclaimer: {
    color: 'white',
  },
  divider: {
    width: '100%',
  },
  navLink: {
    color: 'rgba(255,255,255,.6)',
  },
  whiteColor: {
    color: '#fff',
  },
  note: {
    lineHeight: 1.71,
  },
  copyright: {
    fontSize: 14,
    lineHeight: 3.8,
  },
  footnote: {
    lineHeight: 1.71,
    margin: 0,
  },
  link: {
    fontSize: 14,
    letterSpacing: ' 0.29px',
    color: 'rgba(255,255,255,.6)',
  },
  legalLink: {
    fontSize: 14,
    letterSpacing: ' 0.29px',
    color: 'rgba(255,255,255,.6)',
    margin: theme.spacing(3, 1),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

const Footer = props => {
  const {pages, className, ...rest} = props;

  const classes = useStyles();

  const MenuGroup = mgProps => {
    const {item} = mgProps;
    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body2" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body2"
              component="a"
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const Pages = () => (
    <div className={classes.menu}>
      {pages.map((page, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <MenuGroup key={i} item={page} />
      ))}
    </div>
  );

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container direction="row">
          <Grid item xs={12} md={2}>
            <List disablePadding>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <div className={classes.logoContainer}>
                  <a href="/" title="Make School">
                    <Image className={classes.logoImage} src={LogoDark} alt="Make School" />
                  </a>
                </div>
              </ListItem>
            </List>
            <div className={classes.nonprofitDisclaimer}>
              <span>501(c)(3) nonprofit</span>
              <br />
              <span>EIN: 61-1919324</span>
            </div>
          </Grid>
          <Grid item xs={12} md={8} className={classes.menuListContainer}>
            <Grid item>
              <Pages />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={8}>
          <Typography variant="body1" className={clsx(classes.whiteColor, classes.copyright)}>
            ©2021 MakeSchool.org All Rights Reserved.
          </Typography>
        </Grid>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  footnote: '',
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array,

  /**
   * footer footnote to be rendered
   */
  footnote: PropTypes.string,
};

export default Footer;
