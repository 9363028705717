/* eslint-disable no-shadow, react/destructuring-assignment, react/no-array-index-key */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {HeaderSection} from 'components/molecules';
// import {Articles} from './components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
}));

const BlogNewsroom = () => {
  const classes = useStyles();
  // /* eslint-disable-next-line no-unused-vars */
  // const data = useStaticQuery(graphql`
  //   query BlogQuery {
  //     ms {
  //       blogPosts {
  //         nodes {
  //           id
  //           category
  //           excerptText
  //           heroImageThumbnailPath
  //           heroImagePath
  //           featured
  //           releaseDate
  //           releaseDateString
  //           slug
  //           title
  //         }
  //       }
  //     }
  //   }
  // `);
  return (
    <div className={classes.root}>
      <HeaderSection />
      {/* <Articles data={data?.ms?.blogPosts?.nodes} /> */}
    </div>
  );
};

export default BlogNewsroom;
