import React from 'react';
import PropTypes from 'prop-types';
import {
  InstagramWidget,
  HeroCta,
  Section,
  SectionAlternate,
  DiscoverMakeSchool,
} from 'components/organisms';
import {HeaderSection} from 'components/molecules';

import {ImportantDates} from 'views/Admissions/Components';
import RequiredDocs from '../InternationalApplicants/Components';

const UndocumentedStudents = props => {
  const {data, openModal} = props;

  return (
    <div>
      <Section>
        <HeroCta data={data.heroData} description />
      </Section>
      <SectionAlternate>
        <HeaderSection {...data.welcoming} />
      </SectionAlternate>
      <Section>
        <ImportantDates openModal={openModal} />
      </Section>
      <SectionAlternate>
        <HeaderSection {...data.requiredDocuments} />
        <RequiredDocs data={data.requiredDocuments.requiredDocs} />
      </SectionAlternate>
      <Section>
        <DiscoverMakeSchool />
      </Section>
      <SectionAlternate>
        <InstagramWidget />
      </SectionAlternate>
    </div>
  );
};

UndocumentedStudents.propType = {
  openModal: PropTypes.func,
  data: PropTypes.object,
};

export default UndocumentedStudents;
