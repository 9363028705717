import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import {HeaderSection} from 'components/molecules';
import {YouTube} from 'components/atoms';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));
const LifeOnCampus = props => {
  const {data, className, rest} = props;
  const classes = useStyles();

  return (
    <Grid container justify="center" className={className} {...rest}>
      <Grid item xs={12} md={8}>
        <HeaderSection title={data.title} />
        <YouTube alt={data.video.title} videoId={data.video.id} />
        <Typography variant="body1" className={classes.marginTop}>
          {data.note}
        </Typography>
      </Grid>
    </Grid>
  );
};

LifeOnCampus.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};
export default LifeOnCampus;
