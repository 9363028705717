import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Divider} from '@material-ui/core';
import {Topbar, Footer} from './components';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const Main = ({children, themeMode}) => {
  const classes = useStyles();

  const footerPages = [
    {
      groupTitle: 'Additional Links',
      pages: [
        {
          title: `Donate`,
          href: process.env.GATSBY_MS_DONATION_LINK,
        },
        {
          title: `Online Courses`,
          href: process.env.GATSBY_MS_START_LEARNING_LINK,
        },
      ],
    },
  ].filter(Boolean);

  const pages = {};

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar pages={pages} themeMode={themeMode} />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={footerPages} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
