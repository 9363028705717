import {FaLayerGroup, FaSketch, FaCode} from 'react-icons/fa';

// eslint-disable-next-line import/prefer-default-export
export const courses = [
  {
    icon: FaLayerGroup,
    title: 'PROFESSIONAL DEVELOPMENT',
    subtitle:
      "You'll develop the professionalism, proactive communication, and work ethic needs to excel in your job as a software engineer or product manager. These soft skills paired with real world product development and software engineering best practices will enable your success.",
  },
  {
    icon: FaSketch,
    title: 'CAREER DEVELOPEMENT TUTORIALS',
    subtitle:
      "You'll use asynchronous tutorials to help you draft the materials you’ll need for a successful career. Tutorials are created with the support, review, and approval of partners from the tech industry",
  },
  {
    icon: FaCode,
    title: 'CAREER COACHING & MENTORSHIP',
    subtitle: `You’ll get access to 1:1 coaching that starts at orientation and lasts until you land your dream job. Coaching entails:

Resume, Portfolio, LinkedIn and Github guidance
Job/internship search strategies
Technical/Behavioral mock interviews
Salary negotiation coaching
Industry exposure from Demo Days, Career Fairs and panels`,
  },
];
