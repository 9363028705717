import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Toolbar, List} from '@material-ui/core';
import {fit} from '@cloudinary/base/actions/resize';
import {LoginStateCTA, Image} from 'components/atoms';
import {MSLogoIconURL, MSLogoURL} from 'components/atoms/CloudinaryImage';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth - theme.spacing(8) * 2,
    width: '100%',
    margin: '0 auto',
    padding: '0 auto',
    '@media (max-width: 1164px)': {
      padding: theme.spacing(0, 2),
    },
  },
  navLink: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('sm')]: {
      width: 120,
      height: 32,
    },
  },
  logoImage: {
    width: 'auto',
    height: '100%',
  },
  logoIconImage: {
    width: 'auto',
    height: '100%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
  },
}));

const Topbar = ({themeMode, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();

  const useIcon = useMediaQuery(
    '(max-width: 310px), (min-width: 355px) and (max-width: 420px), (min-width: 960px) and (max-width: 1048px)'
  );
  const logoOpts = img =>
    img.resize(fit().height(useMediaQuery(theme.breakpoints.up('sm')) ? 32 : 28));

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <a href="/" title="Make School">
          <Image
            className={useIcon ? classes.logoIconImage : classes.logoImage}
            src={useIcon ? MSLogoIconURL(logoOpts) : MSLogoURL(themeMode === 'dark', logoOpts)}
            alt="Make School"
            lazy={false}
          />
        </a>
      </div>
      <div className={classes.flexGrow} />
      <List disablePadding className={classes.navigationContainer}>
        <LoginStateCTA />
      </List>
    </Toolbar>
  );
};

Topbar.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
