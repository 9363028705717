import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Section, SectionAlternate} from 'components/organisms';
import {HeaderSection} from 'components/molecules';
import {Gallery, Partners} from './components';

import {mission, headerData, team, companies, foundersIdea, gallery, contact} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionPartners: {
    boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
    '& .section-alternate__content': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  spacing: {
    margin: '30px',
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Section>
        <HeaderSection
          titleVariant="h2"
          title={mission.title}
          subtitle={mission.subtitle}
          subtitleVariant="h3"
          subtitleColor="primary"
          description={mission.description}
        />
      </Section>
      <SectionAlternate>
        <HeaderSection title={headerData.title} description={headerData.description} />
      </SectionAlternate>
      <Section>
        <HeaderSection title={team.title} description={team.description} />
        <Gallery className={classes.spacing} data={gallery} />
      </Section>
      <SectionAlternate>
        <HeaderSection title={foundersIdea.title} description={foundersIdea.description} />
      </SectionAlternate>
      <Section className={classes.sectionPartners}>
        <Partners data={companies} />
      </Section>
      <SectionAlternate>
        <HeaderSection title={contact.title} description={contact.description} />
      </SectionAlternate>
    </div>
  );
};

export default About;
