import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Image} from 'components/atoms';
import {CardBase, DescriptionListIcon} from 'components/organisms';

const useStyles = makeStyles(_ => ({
  image: {
    width: 'auto',
    height: 'auto',
    maxHeight: 100,
    minHeight: 100,
    minWidth: 100,
  },
}));

const FeatureGrid = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
          >
            <CardBase liftUp variant="outlined">
              <DescriptionListIcon
                icon={<Image src={item.logo} className={classes.image} />}
                title={item.title}
                subtitle={item.description}
              />
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

FeatureGrid.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default FeatureGrid;
