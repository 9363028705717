import React from 'react';
import PropTypes from 'prop-types';
import {
  InstagramWidget,
  HeroCta,
  Section,
  SectionAlternate,
  DiscoverMakeSchool,
} from 'components/organisms';
import {HeaderSection} from 'components/molecules';

import RequiredDocs from './Components';

const InternationalApplicants = props => {
  const {data} = props;

  return (
    <div>
      <Section>
        <HeroCta data={data.heroData} description />
      </Section>
      <SectionAlternate>
        <HeaderSection {...data.firstStep} />
      </SectionAlternate>
      <Section>
        <HeaderSection {...data.secondStep} />
        <RequiredDocs data={data.secondStep.requiredDocs} />
      </Section>
      <SectionAlternate>
        <HeaderSection {...data.thirdStep} />
      </SectionAlternate>
      <Section>
        <HeaderSection {...data.fourthStep} />
      </Section>
      <SectionAlternate>
        <DiscoverMakeSchool />
      </SectionAlternate>
      <Section>
        <InstagramWidget />
      </Section>
    </div>
  );
};

InternationalApplicants.propType = {
  openModal: PropTypes.func,
  data: PropTypes.object,
};

export default InternationalApplicants;
