import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Image} from 'components/atoms';
import {useMediaQuery, Grid, ListItem, ListItemText, Avatar, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  logo: {
    background: 'transparent',
    height: 60,
    width: 'auto',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: 200,
    height: 200,
    border: `4px solid ${theme.palette.alternate.dark}`,
    borderRadius: '100%',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(2),
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  name: {
    fontWeight: 700,
  },
}));

const Reviews = props => {
  const {data, logo, className, ...rest} = props;
  const classes = useStyles(props);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 10 : 6}>
        {data.map((item, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Grid item xs={12} sm={6} key={index}>
            <Grid container spacing={1} alignItems="center" align="center">
              <Grid item xs={12}>
                {logo ? (
                  <Image src={item.logo} className={classes.logo} />
                ) : (
                  <Avatar src={item.photo} className={classes.avatar} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" component="p" color="textPrimary">
                  "{item.feedback}"
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ListItem disableGutters>
                  <ListItemText
                    primary={item.name}
                    secondary={item.background}
                    primaryTypographyProps={{
                      className: classes.name,
                      align: 'center',
                    }}
                    secondaryTypographyProps={{
                      align: 'center',
                    }}
                  />
                </ListItem>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Reviews.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
  logo: PropTypes.bool,
};

export default Reviews;
