import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid} from '@material-ui/core';
import {Image} from 'components/atoms';
import {HeaderSection} from 'components/molecules';

const useStyles = makeStyles(() => ({
  image: {},
}));

const ParagraphImage = props => {
  const {data, swapped, className, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const useDirection = () => {
    const direction = isMd ? 'row' : 'column';
    return swapped ? `${direction}-reverse` : direction;
  };

  return (
    <div className={className} {...rest}>
      <Grid container justify="space-between" spacing={isMd ? 4 : 2} direction={useDirection()}>
        <Grid item container alignItems="center" justify="flex-start" xs={12} md={6}>
          <div>
            <HeaderSection
              title={data.title}
              description={data.text}
              align="left"
              disableGutter
              fullWidth
            />
          </div>
        </Grid>
        <Grid
          item
          container
          justify={isMd ? 'flex-end' : 'center'}
          alignItems="center"
          xs={12}
          md={6}
        >
          <Image src={data.image} alt="Image" className={classes.image} />
        </Grid>
      </Grid>
    </div>
  );
};

ParagraphImage.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  swapped: PropTypes.bool,
};

export default ParagraphImage;
