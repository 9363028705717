import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid} from '@material-ui/core';

import {Video} from 'components/atoms';
import {HeaderSection} from 'components/molecules';

const useStyles = makeStyles(theme => ({
  video: {
    position: 'relative',
  },
  videoWrapper: {
    width: '100%',
    height: '100%',
  },
  videoCover: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.primary.main,
    opacity: '0.3',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  videoPlayButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: 70,
    zIndex: 1300,
    boxShadow: `0 8px 21px 0 ${theme.palette.cardShadow}`,
    borderRadius: '100%',
  },
}));

const VideoSection = props => {
  const {data, rowReverse, className, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const row = rowReverse ? 'row-reverse' : 'row';

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 8 : 6}
        direction={isMd ? row : 'column'}
      >
        <Grid item container justify="center" xs={12} md={6}>
          <div className={classes.videoWrapper}>
            <Video videoId={data.videoId} alt={data.alt} className={classes.video} preview />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <HeaderSection
                title={<span>{data.title}</span>}
                description={data.subtitle}
                align="left"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

VideoSection.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  rowReverse: PropTypes.bool,
};

export default VideoSection;
