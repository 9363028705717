import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {HeaderSection} from 'components/molecules';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  fontWeight600: {
    fontWeight: 600,
  },
  alignStart: {
    verticalAlign: 'top',
  },
  withPadding: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  table: {
    overflowY: 'hidden',
  },
  cellFixedWidth: {
    width: '40%',
  },
  tableWidth: {
    minWidth: 600,
  },
  noPaddingX: {
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
}));
const RequiredDocs = props => {
  const {
    data: {title, tableData, note},
  } = props;
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item container md={8} xs={12} className={classes.withPadding} spacing={2}>
        <Grid item xs={12}>
          <HeaderSection title={title} titleVariant="h5" fullWidth />
        </Grid>
        <Grid item xs={12} className={classes.noPaddingX}>
          <TableContainer className={clsx(classes.table, classes.noPaddingX)} component={Paper}>
            <Table className={classes.tableWidth}>
              <TableHead>
                <TableRow>
                  {tableData.header.map(el => (
                    <TableCell>
                      <HeaderSection
                        title={el}
                        titleVariant="body1"
                        titleClasses={classes.fontWeight600}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.rows.map(row => (
                  <TableRow className={classes.alignStart}>
                    <TableCell className={classes.cellFixedWidth}>
                      <HeaderSection
                        title={row.what.title}
                        titleVariant="body1"
                        titleClasses={classes.fontWeight600}
                        description={row.what.description}
                        align="left"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" align="left">
                        {row.how.description}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {note && note}
      </Grid>
    </Grid>
  );
};

RequiredDocs.propTypes = {
  data: PropTypes.object,
};

export default RequiredDocs;
