import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import {HeaderSection} from 'components/molecules';
import {CardBase} from 'components/organisms';

const useStyles = makeStyles(theme => ({
  cardBase: {
    boxShadow: 'none',
    background: theme.palette.alternate.main,
    borderRadius: theme.spacing(1),
    '& .card-base__content': {
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
    },
  },
  avatar: {
    width: 110,
    height: 110,
    border: `4px solid ${theme.palette.alternate.dark}`,
    borderRadius: '100%',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  },
  listItem: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listItemAvatar: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: 0,
    height: '100%',
  },
  title: {
    fontWeight: 900,
  },
  role: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    padding: theme.spacing(1, 0, 1, 0),
  },
  background: {
    color: theme.palette.text.secondary,
  },
}));

const Team = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <HeaderSection
        title="Our faculty and staff have worked at leading universities and tech companies"
        description="Our faculty brings together expertise from leading institutions in higher ed and tech like MIT and Google. We came together to help fix the challenges facing higher education. We listen to our students, your ideas are key to building the college of the future. We are aiming to build our college in a transparent way - akin to an open source software project. We publish our Institutional Plan openly on Github - including areas we need to improve on - and welcome student feedback in all forms."
        disableGutter={false}
        fullWidth
      />
      <Grid container spacing={isMd ? 2 : 1}>
        {data.map((item, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Grid item xs={6} sm={6} md={4} key={index}>
            <CardBase className={classes.cardBase} liftUp>
              <ListItem disableGutters className={classes.listItem}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar {...item.authorPhoto} alt={item.authorName} className={classes.avatar} />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={<>{item.authorName}</>}
                  secondary={
                    <>
                      <div className={classes.role}>{item.role}</div>
                      <div className={classes.background}>{item.background}</div>
                    </>
                  }
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: 'h6',
                    align: isMd ? 'left' : 'center',
                    component: 'div',
                  }}
                  secondaryTypographyProps={{
                    color: 'textPrimary',
                    align: isMd ? 'left' : 'center',
                    component: 'div',
                  }}
                />
              </ListItem>
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Team.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Team;
