import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button, Avatar, Typography} from '@material-ui/core';
import {Image} from 'components/atoms';
import {HeaderSection} from 'components/molecules';
import {DescriptionListIcon} from 'components/organisms';

const useStyles = makeStyles(_ => ({
  avatar: {
    width: 70,
    height: 70,
    borderRadius: 0,
    background: 'transparent',
  },
}));

const Integrations = props => {
  const {data, openModal, className, ...rest} = props;
  const classes = useStyles();

  const title = data.topOutComesTagline ?? (
    <span>
      A New{' '}
      <Typography component="span" variant="inherit" color="primary">
        University
      </Typography>
      <br />
      is Born.
    </span>
  );

  const subtitle =
    data.topOutcomesSummary ??
    `Make School is pursuing independent accreditation to become the first new university purpose-built to prepare underrepresented talent for top tech careers.`;

  return (
    <div className={className} {...rest}>
      <HeaderSection title={title} titleVariant="h4" subtitle={subtitle} />
      <Grid container spacing={1} direction="row" justify="center" alignItems="center">
        {data.logos.map((item, index) => (
          <Grid
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={12}
            sm={6}
            md={4}
            lg={item.size}
          >
            <DescriptionListIcon
              icon={
                <Avatar className={classes.avatar}>
                  <Image src={item.logo} alt={item.name} />
                </Avatar>
              }
              title={item.name}
              align="center"
              size={item.size}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} direction="row" justify="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => openModal('companies')}
        >
          Contact Admissions
        </Button>
      </Grid>
    </div>
  );
};

Integrations.propTypes = {
  openModal: PropTypes.func,
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default Integrations;
