import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
  Section,
  SectionAlternate,
  ListWithImage,
  InstagramWidget,
  DiscoverMakeSchool,
} from 'components/organisms';
import {Hero, Integrations, StudentTestimonials, SocialProof} from './Components';
import {courses} from './data';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  reviewSection: {
    background: theme.palette.primary.dark,
  },
}));

const Outcomes = props => {
  const {openModal, data} = props;
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <Hero titleVariant="h2" openModal={openModal} data={data} />
      </Section>
      <SectionAlternate>
        <StudentTestimonials data={data} openModal={openModal} />
      </SectionAlternate>
      <Section>
        <Integrations data={data} openModal={openModal} />
      </Section>
      <SectionAlternate className={classes.reviewSection}>
        <SocialProof data={data} openModal={openModal} />
      </SectionAlternate>
      <Section>
        <ListWithImage title="We support your career placement through" data={courses} />
      </Section>
      <SectionAlternate>
        <DiscoverMakeSchool />
      </SectionAlternate>
      <Section>
        <InstagramWidget />
      </Section>
    </div>
  );
};

Outcomes.propTypes = {
  openModal: PropTypes.func,
  data: PropTypes.object.isRequired,
};

export default Outcomes;
