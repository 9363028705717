import React from 'react';
import {Link} from 'gatsby-theme-material-ui';

export const mission = {
  title: 'About Make School',
  subtitle: 'Our Mission Statement',
  description: (
    <div>
      <p>
        Make School’s mission is to create avenues of upward mobility for students of all
        backgrounds, empowering them to contribute to society through science and technology
        innovation.
      </p>
      <p>
        We fulfill this mission by serving a majority under-resourced and underrepresented student
        population through a Bachelor’s degree program, countering systemic barriers to upward
        mobility and preparing them for high-earning careers.
      </p>
      <p>
        Make School is built on the following values:
        <ul>
          <li>
            <strong>Students-first</strong> - we put student needs first when making decisions and
            protect students’ investment in their education through innovative financing options.
          </li>
          <li>
            <strong>Innovation</strong> - we operate using progressive organizational principles,
            cutting-edge education methodologies, and regular experimentation.
          </li>
          <li>
            <strong>Relevance</strong> - we develop curriculum and partnerships to ensure the
            education we provide prepares students for productive careers in industry and to be
            engaged members of civil society.
          </li>
          <li>
            <strong>Equity</strong> - we analyze our actions and decisions through the lenses of
            Justice, Equity, Diversity, and Inclusion in order to create a more equitable future for
            all. We serve students traditionally underrepresented in the technology industry.
          </li>
          <li>
            <strong>Citizenship</strong> - our students actively engage with their communities and
            create positive social impact. Students work on projects and seek employment to help
            solve key societal problems. Students formalize their ethical frameworks, practice
            empathy, respect diversity, and reject bias.
          </li>
          <li>
            <strong>Personal Growth</strong> - we provide educational resources and mentorship to
            help students and staff develop a growth mindset and achieve personal growth.
          </li>
          <li>
            <strong>Transparency</strong> - our policies, practices, planning, offerings, and
            student outcomes are hosted on a public-facing, version-controlled platform. Our
            affiliations with partners are publicly disclosed.
          </li>
        </ul>
      </p>
    </div>
  ),
};

export const headerData = {
  title: 'Make School is redesigning higher education for the 21st century',
  description: (
    <div>
      <p>
        Our emphasis is on ensuring relevance of education for the modern economy and accessibility
        to students of all backgrounds. We provide instructional, curricular, admissions, and other
        services to help operate innovative new college programs in partnership with established
        universities. Our education operates on the following pillars:
      </p>

      <ul>
        <li>
          <span>
            Combining the liberal arts with computing education in a bachelor’s granting program
          </span>
        </li>

        <li>
          <span>Providing relevant and engaging education through real world projects</span>
        </li>

        <li>
          <span>
            Uniting students from a broad socioeconomic range who possess grit (as measured by
            non-traditional metrics) and a passion for impact and innovation
          </span>
        </li>

        <li>
          <span>Ensuring students are the core focus of the institution</span>
        </li>

        <li>
          <span>
            Systemically aligning incentives of the institution with students through a repayment
            protection plan that ensures tuition is paid as a percentage of salary once employed
          </span>
        </li>
      </ul>

      <p>
        The students we’ve educated have shipped thousands of apps reaching tens of millions of
        people; from medical solutions to women's empowerment communities to simple games. Our
        alumni form a powerful network across the most innovative companies in the world.
      </p>

      <p>
        The majority of the students in the Bachelor’s in Applied Computer Science program come from
        low to mid income families, while nearly half identify as underrepresented minority
        students. Their career outcomes are on par with students from elite universities, giving us
        evidence that talent is in fact diverse and distributed.
      </p>

      <p>
        We hope our experiment will inspire innovation throughout the higher education landscape and
        accelerate the broader conscious capitalism movement.
      </p>
      <div style={{textAlign: 'center'}}>
        <Link to="/vision" target="">
          <span>Read our full vision &#8594;</span>
        </Link>
      </div>
    </div>
  ),
};

export const team = {
  title: 'Our team is our operational engine and cultural core',
  description: (
    <div>
      <p>
        We are fortunate to work with a diverse group of individuals passionate about making higher
        education more modern, engaging, and accessible. We've previously worked at tech companies,
        coding schools, universities and integrate our knowledge and experiences to drive
        innovation. We've lectured and taught courses at MIT, Carnegie Mellon, UC Berkeley, Stanford
        and INSEAD. We strive to treat people well, do right by our students and our team, and
        support our surrounding community. We hope everyone who enters our doors, whether students
        or staff, leaves better off than as they came.
      </p>
      <div style={{textAlign: 'center'}}>
        <Link to="/careers" target="">
          <span>Explore careers at Make School &#8594;</span>
        </Link>
      </div>
    </div>
  ),
};

export const companies = [
  {
    name: 'Learn Capital',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/c_crop,h_178,w_527,x_0,y_14/v1/CompanyLogos/learn-capital-logo.png',
    description: 'Driving educational innovation globally with the world bank and impact LPs',
  },
  {
    name: 'Kapor Capital',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/c_crop,h_108,w_320/v1/CompanyLogos/kapor-capital.png',
    description: 'Creating upward mobility for low-income youth and improving diversity in tech',
  },
  {
    name: 'Y Combinator',
    logo: 'https://res.cloudinary.com/makeschool/image/upload/c_fit,h_108,w_320/v1/CompanyLogos/Y_Combinator_logo.png',
    description: 'Building a better world through technology, research, and non-profits',
  },
];

export const gallery = [
  {
    image: {
      src: 'https://res.cloudinary.com/makeschool/image/upload/c_fit,w_1048,f_auto,fl_lossy,q_auto/v1/About/ms_team_jan_2020.jpg',
    },
    title: 'our team',
    cols: 3,
  },
];

export const foundersIdea = {
  title: 'Our founders designed the college they wished existed',
  description: (
    <div>
      <p>
        Seven years ago, Ashu and Jeremy took time off college because they didn't feel engaged with
        traditional education. They were products of a global progressive education system, but felt
        modern education pedagogy was missing in universities. During their time off they began
        teaching high school and college students to build apps, a way to supplement traditional
        education with a relevant and engaging experience that would prepare students for careers in
        the tech industry. Their students fell in love with progressive education and built
        portfolios that enabled them to earn jobs at top tech companies. The stories and experience
        of their students inspired Ashu and Jeremy to begin designing a new model of higher
        education.
      </p>
      <div style={{textAlign: 'center'}}>
        <Link to="/vision#section_2" target="">
          <span>Read our founding story &#8594;</span>
        </Link>
      </div>
    </div>
  ),
};

export const contact = {
  title: 'CONTACT US',
  description: (
    <div style={{display: 'flex'}}>
      <p style={{marginRight: '25px'}}>
        <strong>Email:</strong> <Link href="mailto:hello@makeschool.org">hello@makeschool.org</Link>
      </p>
      <p>
        <strong>Address: </strong>
        <Link href="https://goo.gl/maps/m4NYb">555 Post Street San Francisco, CA 94102</Link>
      </p>
    </div>
  ),
};
