import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Typography} from '@material-ui/core';
import {Image} from 'components/atoms';
import {HeaderSection} from 'components/molecules';

const useStyles = makeStyles(() => ({
  promoLogo: {
    width: '71%',
    height: 'auto',
  },
  title: {
    fontWeight: 'bold',
  },
  centered: {
    textAlign: 'center',
  },
}));

const Partners = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <HeaderSection title="Our investors care deeply about impact" disableGutter />
        </Grid>
        <Grid item container xs={12} md={12}>
          {data.map((partner, index) => (
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              xs={4}
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
            >
              <Image
                src={partner.logo}
                alt={partner.name}
                className={classes.promoLogo}
                lazy={false}
              />
              <Typography className={classes.centered} variant="subtitle1">
                {partner.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

Partners.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Partners;
