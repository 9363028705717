import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {Section} from 'components/organisms';
import {Content, Hero} from './components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
}));

const BlogArticle = ({pageContext}) => {
  const classes = useStyles();
  const {blog} = pageContext;
  return (
    <div className={classes.root}>
      <Hero
        cover={blog.heroImagePath}
        title={blog.title}
        subtitle={blog.subtitle}
        date={blog.releaseDateString}
        tags={[blog.category]}
        align="left"
      />
      <Section>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Content data={blog} />
          </Grid>
        </Grid>
      </Section>
    </div>
  );
};

export default BlogArticle;
