import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {LearnMoreLink} from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
    },
  },
  learnMore: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  text: {
    fontWeight: 700,
  },
}));

const LearnMore = props => {
  const {className, primaryLink, secondaryLink, ...rest} = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {primaryLink && (
        <LearnMoreLink
          href={primaryLink.url}
          title={primaryLink.text}
          className={classes.learnMore}
          variant="h4"
          typographyProps={{className: classes.text, variant: 'h4'}}
          iconProps={{visibility: 'hidden'}}
        />
      )}
      <br />
      {secondaryLink && (
        <LearnMoreLink
          href={secondaryLink.url}
          title={secondaryLink.text}
          className={classes.learnMore}
          variant="h4"
          typographyProps={{className: classes.text}}
          iconProps={{visibility: 'hidden'}}
        />
      )}
    </div>
  );
};

LearnMore.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  primaryLink: PropTypes.object,
  secondaryLink: PropTypes.object,
};

export default LearnMore;
