import {Button} from 'gatsby-theme-material-ui';
import React from 'react';
import {ListItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import useCookie from 'data/use-cookie';

const useStyles = makeStyles(theme => ({
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.primary.dark,
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0,
    },
  },
}));

const defaultLink = {
  primary: {
    text: 'Donate',
    link: process.env.GATSBY_MS_DONATION_LINK,
  },
  secondary: {
    text: 'Learn',
    link: process.env.GATSBY_MS_START_LEARNING_LINK,
  },
};

const learnLink = {
  text: 'Learn',
  link: process.env.GATSBY_MS_START_LEARNING_LINK,
};

const defaultLinks = {
  admin: {
    primary: {
      text: 'Admin',
      link: '/admin',
    },
    secondary: learnLink,
  },
  student: {
    primary: {
      text: 'Portal',
      link: '/portal',
    },
    secondary: learnLink,
  },
  user: {
    ...defaultLink,
  },
  default: {
    ...defaultLink,
  },
};

const LoginStateCTA = ({links, hideSecondary}) => {
  // const [userType, _] = useCookie('_makeschool');
  const userType = false;
  const classes = useStyles();

  const mergedLinks = {...defaultLinks, ...links};
  const link = userType ? mergedLinks[userType] : mergedLinks.default;

  const trackLoginCTA = ctaInfo => {
    return () => window?.analytics?.track('Use LoginStateCTA', ctaInfo);
  };

  const externalProps = url => {
    // return url?.startsWith('/') ? {} : {target: '_blank', rel: 'noreferrer'};
    return {};
  };

  return (
    <>
      {!hideSecondary && (
        <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
          <Button
            variant="outlined"
            component="a"
            href={link.secondary.link}
            className={classes.listItemButton}
            onClick={trackLoginCTA({userType, ...link.secondary})}
            {...externalProps(link.secondary.link)}
          >
            {link.secondary.text}
          </Button>
        </ListItem>
      )}
      <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
        <Button
          variant="contained"
          color="primary"
          component="a"
          href={link.primary.link}
          className={classes.listItemButton}
          onClick={trackLoginCTA({userType, ...link.primary})}
          {...externalProps(link.primary.link)}
        >
          {link.primary.text}
        </Button>
      </ListItem>
    </>
  );
};

LoginStateCTA.defaultProps = {
  hideSecondary: false,
  links: {},
};

const propTypeLink = PropTypes.shape({
  text: PropTypes.string,
  link: PropTypes.string,
});

LoginStateCTA.propTypes = {
  hideSecondary: PropTypes.bool,
  links: PropTypes.shape({
    primary: propTypeLink,
    secondary: propTypeLink,
  }),
};

export default LoginStateCTA;
