import React from 'react';
import {Link} from 'gatsby-theme-material-ui';

const cookieData = {
  title: 'Cookie Policy',
  description: (
    <div className="container group">
      <p>
        This cookies policy applies when using Make School websites, services and products
        (collectively, our "<strong>Service</strong>").
      </p>
      <p>
        We use cookies and similar technologies to distinguish you from other users of the Service.
        This helps us to provide you with a good experience when you browse the Service and also
        allows us to improve the Service.
      </p>

      <p>
        Cookies are pieces of code that allow for personalisation of the Service experience by
        saving your information such as user ID and other preferences. A cookie is a small data file
        that we transfer to your device's hard disk (such as your computer or smartphone) for
        record-keeping purposes.
      </p>

      <p>Please click on the links for more information about the cookies that we use.</p>

      <ol>
        <li>
          <strong>
            <Link to="/cookie-policy/strictly-necessary-cookies/">Strictly necessary cookies.</Link>
          </strong>
          These are cookies that are required for the essential operation of the Service such as to
          authenticate users and prevent fraudulent use.
        </li>
        <li>
          <strong>
            <Link to="/cookie-policy/analytical-performance-cookies/">
              Analytical/performance cookies.
            </Link>
          </strong>
          These allow us to recognise and count the number of visitors and to see how visitors move
          around the Service when they are using it. This helps us to improve the way the Service
          works, for example, by ensuring that users are finding what they are looking for easily.
          We may use third-party analytics tools to help us measure traffic and usage trends and to
          understand more about the demographics of our users.
        </li>
        <li>
          <strong>
            <Link to="/cookie-policy/functionality-cookies/">Functionality cookies.</Link>
          </strong>
          These are used to recognise you when you return to the Service. This enables us to
          personalise our content for you, greet you by name and remember your preferences (for
          example, your choice of language or region).
        </li>
        <li>
          The cookies we use are designed to help you get the most from the Service but if you do
          not wish to receive cookies, most browsers allow you to change your cookie settings.
          Please note that if you choose to refuse cookies you may not be able to use the full
          functionality of the Service. These settings will typically be found in the "options" or
          "preferences" menu of your browser. In order to understand these settings, the following
          links may be helpful, otherwise you should use the "Help" option in your browser for more
          details.
          <p>
            6.1
            <Link
              href="https://support.microsoft.com/en-us/products/windows?os=windows-10"
              target="_blank"
            >
              Cookie settings in Internet Explorer
            </Link>
          </p>
          <p>
            6.2
            <Link
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
              target="_blank"
            >
              Cookie settings in Firefox
            </Link>
          </p>
          <p>
            6.3
            <Link href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">
              Cookie settings in Chrome
            </Link>
          </p>
          <p>
            6.4
            <Link href="https://support.apple.com/kb/PH5042?locale=en_GB" target="_blank">
              Cookie settings in Safari web
            </Link>
            and
            <Link href="https://support.apple.com/en-gb/HT201265" target="_blank">
              iOS
            </Link>
            .
          </p>
        </li>
        <li>
          We participate in interest-based advertising and use third party advertising companies to
          serve you targeted advertisements based on your online browsing history and your
          interests. To do this, we or our advertising partners may collect information about how
          you use or connect to our Service, or the types of other websites, social media services,
          content and ads that you (or others using your device) visit or view or connect to our
          Service. Typically, this information is collected through cookies and similar tracking
          technologies.
        </li>
        <li>
          If you only want to limit third party advertising cookies, you can turn such cookies off
          by visiting the following links (please bear in mind that there are many more companies
          listed on these sites than those that drop cookies via our website):
        </li>
        <li>
          <p>
            8.1
            <Link href="http://www.youronlinechoices.com/" target="_blank">
              Your Online Choices
            </Link>
          </p>

          <p>
            8.2
            <Link href="https://www.networkadvertising.org/" target="_blank">
              Network Advertising Initiative
            </Link>
          </p>

          <p>
            8.3
            <Link href="http://www.aboutads.info/consumers" target="_blank">
              Digital Advertising Alliance
            </Link>
          </p>
        </li>
        <li>
          We also use clear gifs in HTML-based emails sent to our customers to track which emails
          are opened and which links are clicked by recipients. The information allows for more
          accurate reporting and improvement of our Service. You can set your e-mail options to
          prevent the automatic downloading of images that may contain these technologies that would
          allow us to know whether you have accessed our e-mail and performed certain functions with
          it.
        </li>
        <li>
          If you would like to find out more about cookies and other similar technologies, please
          visit{' '}
          <Link href="http://www.allaboutcookies.org" target="_blank">
            allaboutcookies.org
          </Link>{' '}
          or the Network Advertising Initiative's online sources at{' '}
          <Link href="https://www.networkadvertising.org" target="_blank">
            networkadvertising.org
          </Link>
          .
        </li>
      </ol>
    </div>
  ),
};

export default cookieData;
