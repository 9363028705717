import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {Image} from 'components/atoms';
import {HeaderSection} from 'components/molecules';
import {CardImage} from 'components/organisms';

const useStyles = makeStyles(theme => ({
  cardBase: {
    padding: theme.spacing(0),
  },
  fontWeight700: {
    fontWeight: 700,
  },
  image: {
    width: '100%',
    padding: theme.spacing(0),
  },
}));

const TitleImages = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <HeaderSection title={<span>{data.title}</span>} subtitle={data.subtitle} />
      <Grid container spacing={2}>
        {data.images.map((item, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Grid key={index} item xs={12} sm={12} md={4}>
            <CardImage withShadow liftUp className={classes.cardBase}>
              {/* eslint-disable-next-line react/no-array-index-key */}
              <Image src={item.image} alt={item.alt} className={classes.image} />
            </CardImage>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

TitleImages.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default TitleImages;
