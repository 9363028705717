import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import {Typography} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  withBG: {
    backgroundColor: grey[200],
  },
});

const BasicTable = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <TableContainer className={clsx(className)} component={Paper} {...rest}>
        <Table className={classes.table} aria-label="simple table">
          {data.caption && <caption>{data.caption}</caption>}
          {data.tableHead && (
            <TableHead className={classes.withBG}>
              <TableRow>
                {data.tableHead.map(cell => (
                  <TableCell colSpan={3}>
                    <Typography variant="body1" color="textPrimary">
                      {cell}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.rows.map(row => (
              <TableRow key={row} hover>
                {row.map(el => (
                  <TableCell align="left">{el}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.note && (
        <Typography variant="inherit" component="span">
          {data.note}
        </Typography>
      )}
    </div>
  );
};
BasicTable.prototype = {
  className: PropTypes.string,
  data: PropTypes.object,
};
export default BasicTable;
