import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Typography} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import {Section} from 'components/organisms';
import {HomepageHeroCroppedURL, GivingHeroCroppedURL} from 'components/atoms/CloudinaryImage';

const useStyles = makeStyles(theme => ({
  hero: {
    [theme.breakpoints.up('md')]: {
      background: `url(${HomepageHeroCroppedURL()}) no-repeat right top`,
      backgroundSize: 'contain',
    },
  },
  heroGiving: {
    [theme.breakpoints.up('md')]: {
      background: `url(${GivingHeroCroppedURL()}) no-repeat right top`,
      backgroundSize: 'contain',
    },
  },
  heroWrapper: {
    position: 'relative',
  },
  heroGrid: {
    // maxWidth: 926,
  },
  searchGrid: {
    zIndex: 3,
  },
  searchGridText: {
    // maxWidth: 605,
  },
  textField: {
    width: '100%',
  },
  searchButton: {
    width: '100%',
    height: '100%',
  },
  searchIcon: {
    color: grey[600],
  },
  title: {
    fontWeight: 'bold',
  },
  promoLogo: {
    maxWidth: 100,
  },
  employers: {
    fontWeight: 900,
  },
}));

const Hero = ({title, subtitle, image, footer}) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <section className={classes[image]}>
      <Section className={classes.heroWrapper}>
        <Grid container spacing={4} className={classes.heroGrid}>
          <Grid item xs={isMd ? 8 : 12} className={classes.searchGridText}>
            <Typography variant="h2" color="textPrimary" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={isMd ? 8 : 12} className={classes.searchGridText}>
            <Typography variant="h5" color="textSecondary">
              {subtitle}
            </Typography>
          </Grid>
          {footer && (
            <Grid item xs={isMd ? 8 : 12}>
              {footer}
            </Grid>
          )}
        </Grid>
      </Section>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  footer: PropTypes.object,
};

export default Hero;
