import React from 'react';
import {Link} from 'gatsby-theme-material-ui';

export const headerData = {
  title: 'Press and Testimonials',
  subtitle:
    'Make School was built on the desire to provide students with an accessible, relevant, and practical education that will set them up for long-term success in their careers. Our new Bachelor in Applied Computer Science is a culmination of that desire. We’re excited to push higher education towards higher accountability, putting student outcomes first.',
  theyTalkAboutUs: [
    {
      title: 'ARTICLES ABOUT MAKE SCHOOL',
      articles: [
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Press/Logos/wall-street-journal-logo-transparent.png',
          title: 'COLLEGE AND CODING BOOT CAMP FIND A WAY TO TEAM UP',
          description: (
            <div>
              <p>
                Make School is now accepting applications for a new bachelor’s degree and expects to
                have an independent, accredited three-year degree program within five years.
              </p>
              <span>
                <Link href="https://apple.news/AcMaUxuEVQuGvkKbtjmJNhQ">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Press/Logos/techcrunch_transparent.png',
          title: 'MAKE SCHOOL WANTS TO BUILD THE PRODUCT UNIVERSITY FOR THE MASSES',
          description: (
            <div>
              <p>
                College has failed, or so many Silicon Valley entrepreneurs believe. Make School
                hopes to change this sordid state of affairs.
              </p>
              <span>
                <Link href="https://techcrunch.com/2015/07/21/make-university/">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Press/Logos/bright_magazine_logo.png',
          title: 'FOR SILICON VALLEY HOPEFULS, IS COLLEGE IRRELEVANT?',
          description: (
            <div>
              <p>
                Make School is trying to reinvent how we educate tomorrow’s tech leaders
                prioritizing apps over lectures.
              </p>
              <span>
                <Link href="https://www.mercurynews.com/2017/07/15/silicon-valley-investors-embrace-a-new-vision-of-college/">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: 'ARTICLES ABOUT MAKE SCHOOL STUDENTS',
      articles: [
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Press/Logos/fast-company-logo.svg',
          title:
            'HOW ONE HIGH SCHOOL GRAD STUDIED COMPUTER SCIENCE DEBT-FREE AND NABBED A $90K-SALARY JOB',
          description: (
            <div>
              <p>
                Masa Bando deferred admission to MIT, went to Make School, and got hired as a
                software engineer at a startup.
              </p>
              <span>
                <Link href="https://www.fastcompany.com/3043275/how-one-high-school-grad-studied-computer-science-debt-free-and-nabbed-a-90">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Press/Logos/cult-of-mac-logo.png',
          title: 'HOW A 19-YEAR-OLD WON A FREE SCHOLARSHIP TO WWDC',
          description: (
            <div>
              <p>
                Kenny won a coveted scholarship, which includes food, lodging, keynote access and
                meeting Apple CEO Tim Cook.
              </p>
              <span>
                <Link href="https://www.cultofmac.com/484414/19-year-old-won-free-scholarship-wwdc/">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: 'PARTNERSHIPS',
      articles: [
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Press/Logos/facebook.svg',
          title: 'FACEBOOK LOOKS TO MAKE SCHOOL’S STUDENTS FOR THEIR NEXT GENERATION OF INNOVATORS',
          description: (
            <div>
              <p>
                Under a new agreement we just signed, Facebook will actively look for new recruits
                within Make School's student body, and Facebook will host events at the main
                Facebook campus...
              </p>
              <span>
                <Link
                  to="/blog/facebook-looks-to-make-schools-students-for-their-next-generation-of-innovators/"
                  target="_blank"
                >
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Press/Logos/lever_logo.svg',
          title: 'LEVER: PROMOTING NON-TRADITIONAL BACKGROUNDS IN TECH',
          description: (
            <div>
              <p>
                Make School was founded to empower students to build and ship products. Creating a
                product that improves the lives of others is a thrilling...
              </p>
              <span>
                <Link to="/blog/promoting-nontraditional-backgrounds-intech">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
      ],
    },
    {
      title: 'TESTIMONIALS',
      articles: [
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Blog/tia-king-hero.jpg',
          title: 'FINDING YOUR OWN WAY WITH TIA KING',
          description: (
            <div>
              <p>
                "Between the coaching and the classes...it's all encouraged me to live my own
                truth...and that has given me a confidence boost as I go out into the world as a
                black woman in tech."
              </p>
              <span>
                <Link to="/blog/finding-your-own-way-with-tia-king/" target="_blank">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Blog/victoria-murray-hero.jpg',
          title: 'GOING AFTER THE DREAM WITH VICTORIA MURRAY',
          description: (
            <div>
              <p>
                "I did the Summer Academy before moving onto art school...But six weeks into my new
                program, I knew the education I was getting couldn't compare to the education I saw
                my Make School friends get…"
              </p>
              <span>
                <Link to="/blog/going-after-the-dream-with-victoria-murray/" target="_blank">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
        {
          image:
            'https://res.cloudinary.com/makeschool/image/upload/t_600x600_fit/v1/Blog/jacob-haff-hero.jpg',
          title: 'ENGAGING IN CREATIVE DESTRUCTION WITH JACOB HAFF',
          description: (
            <div>
              <p>
                "Make School focuses on so much more than just making you a spectacular software
                engineer,' Jacob says. 'Make School equips its students with the tools they need to
                find work!"
              </p>
              <span>
                <Link to="/blog/engaging-in-creative-destruction-with-jacob-haff/" target="_blank">
                  <span>View &#8594;</span>
                </Link>
              </span>
            </div>
          ),
        },
      ],
    },
  ],
};

export const materials = {
  title: 'Materials & Media',
  description: (
    <div>
      <div>
        <h4>Our Vision</h4>
        <Link href="/vision">
          <span>
            <span>View &#8594;</span>
          </span>
        </Link>
      </div>

      <div>
        <h4>Brand Assets and Photos</h4>
        <Link href="https://make.sc/presskit">
          <span>
            <span>View &#8594;</span>
          </span>
        </Link>
      </div>

      <div>
        <h4>Industry Research</h4>
        <Link href="https://make.sc/industry-research">
          <span>
            <span>View &#8594;</span>
          </span>
        </Link>
      </div>

      <div>
        <h4>Student Demographics</h4>
        <Link href="https://make.sc/demographics-2018">
          <span>
            <span>View &#8594;</span>
          </span>
        </Link>
      </div>

      <div>
        <h4>Alumni Outcomes</h4>
        <Link href="https://make.sc/outcomes">
          <span>
            <span>View &#8594;</span>
          </span>
        </Link>
      </div>

      <div>
        <h4>Brand Guidelines</h4>
        <Link href="https://make.sc/brand-guidelines">
          <span>
            <span>View &#8594;</span>
          </span>
        </Link>
      </div>
    </div>
  ),
};
