import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Box} from '@material-ui/core';
import Img from 'gatsby-image';
import {LearnMoreLink} from '../../../../components/atoms';
import Review from '../Review/Review';

const useStyles = makeStyles(theme => ({
  listGrid: {
    overflow: 'hidden',
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
  cardGrid: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
}));

const StudentTestimonials = props => {
  const {data, className, openModal, ...rest} = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const trackTestimonialView = student => {
    if (window.analytics) {
      window.analytics.track('Info - Testimonial - View', {
        ctaLocation: `${window.location.pathname}-${student}`,
      });
    }
    return true;
  };

  return (
    <>
      <Box className={className} {...rest}>
        <Box pb={4}>
          <Review data={data} />
        </Box>
        <Grid container justify="center">
          <Grid
            item
            container
            spacing={isMd ? 4 : 2}
            alignItems="center"
            justify="space-between"
            direction="row"
            className={classes.listGrid}
          >
            <Grid item xs={12} sm={6}>
              <a
                href="https://www.linkedin.com/posts/salvadorbecerra_after-2-years-at-make-school-im-happy-to-activity-6726966078758637568-ya_Q"
                onClick={() => trackTestimonialView('salvador')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.salvadorBecerra.childImageSharp.fluid}
                  alt="After 2 years at Make School I’m happy to say I accepted an offer at Microsoft! When I dropped out of college 4 years I had no idea what I was gonna do and now I’m gonna be working at my dream company! I couldn’t have done it without help from others so if anyone needs advice please message me!"
                  loading="lazy"
                  className={classes.cardGrid}
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={6}>
              <a
                href="https://www.linkedin.com/in/arianeevans/?trk=public_post-embed_share-update_actor-text"
                onClick={() => trackTestimonialView('ariane')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.arianeEvans.childImageSharp.fluid}
                  alt="After many months of hard work and dedication, I am happy to say that I have accepted an offer as a Program Manager at Microsoft! I would have never expected this when I first started college as an Acting Major, but am glad I found my true calling at Make School, and am excited to be working for an amazing company! Thank you to all those that have helped me reach my goals, I couldn't have done it without you."
                  loading="lazy"
                  className={classes.cardGrid}
                />
              </a>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a
              href="https://www.facebook.com/nyapal.gatkuoth.1/posts/318994929115560"
              onClick={() => trackTestimonialView('nyapal')}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Img
                fluid={data.nyapalGatkuoth.childImageSharp.fluid}
                alt="Just finishing up my first month at Autodesk and it has been a dream. My team and managers are all amazing & today my code passed review & I completed my first sprint on the job. Everyone who said working is easier than MS was telling the truth."
                loading="lazy"
                className={classes.cardGrid}
              />
            </a>
            <Box ml={1}>
              <LearnMoreLink
                title="Contact Admissions"
                variant="h6"
                onClick={() => openModal('testimonials')}
                href={null}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

StudentTestimonials.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default StudentTestimonials;
