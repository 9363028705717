import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';

const useStyles = makeStyles(() => ({
  extraSmall: {
    fontSize: 10,
  },
  small: {
    fontSize: 20,
  },
  medium: {
    fontSize: 30,
  },
  large: {
    fontSize: 40,
  },
}));

/**
 * Component to display the icon
 *
 * @param {Object} props
 */
const Icon = props => {
  const {reactIcon: ReactIcon, fontIconClass, size, fontIconColor, className, ...rest} = props;

  const classes = useStyles();

  return (
    <NoSsr>
      {ReactIcon ? (
        <ReactIcon style={{color: fontIconColor}} {...rest} />
      ) : (
        <i
          className={clsx('icon', fontIconClass, classes[size], className)}
          style={{color: fontIconColor}}
          {...rest}
        />
      )}
    </NoSsr>
  );
};

Icon.defaultProps = {
  size: 'small',
};

Icon.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * The classes of the font icon
   */
  fontIconClass: PropTypes.string,
  /**
   * A component from react-icons
   */
  reactIcon: PropTypes.func,
  /**
   * Source set for the responsive images
   */
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  /**
   * Color of the icon
   */
  fontIconColor: PropTypes.string,
};

export default Icon;
