import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Helmet} from 'react-helmet';

const SlateFormModal = props => {
  const {open, handleClose, formId} = props;

  const handleSubmit = () => {
    const form = window[`form_${formId}`];
    if (form.Validate()) {
      form.setValue('b3bd53c8-dbc9-401b-bb55-bf14d39834f6', window.location.pathname);
      /* eslint-disable-next-line no-undef */
      FW.Postback(form.getForm(), {cmd: 'submit'}, false);
      if (window.analytics) {
        window.analytics.track('Info - Form - Submit', {});
      }
      handleClose();
    } else if (window.analytics) {
      window.analytics.track('Info - Form - Validation Failed', {});
    }
  };

  const handleCancel = () => {
    if (window.analytics) {
      window.analytics.track('Info - Form - Cancelled', {});
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="learn-more-about-make-school">
      <DialogContent>
        <style type="text/css">
          {`
            #form_question_3e4d43e5-9acd-4e31-84bb-604c407f1b3e {; // address ;
              display: none;
            }
            #form_question_b3bd53c8-dbc9-401b-bb55-bf14d39834f6 {; // landing page field ;
              display: none;
            }
            .action {; // submit button ;
              display: none;
            }
          `}
        </style>
        <div id={`form_${formId}`} className="">
          Loading...
        </div>
        <Helmet>
          <script
            async="async"
            src={`https://apply.makeschool.com/register/?id=${formId}&amp;output=embed&amp;div=form_${formId}`}
          />
        </Helmet>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SlateFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
};

export default SlateFormModal;
