import React, {createContext, useContext} from 'react';

const PageContext = createContext();

const PageContextProvider = incoming => {
  const {pageContext, children} = incoming;
  return <PageContext.Provider value={{...pageContext}}>{children}</PageContext.Provider>;
};

const usePageContext = () => useContext(PageContext);

export {PageContextProvider, usePageContext};
