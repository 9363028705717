import React from 'react';
import PropTypes from 'prop-types';
import {IconAlternate, HeaderSection} from 'components/molecules';
import {Grid, List, ListItem, ListItemText, Typography} from '@material-ui/core';
import {indigo} from '@material-ui/core/colors';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {YouTube} from 'components/atoms';

const useStyles = makeStyles(theme => ({
  circle: {
    borderRadius: '100%',
  },
  small: {
    width: 10,
    height: 10,
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  noMarginBottom: {
    marginBottom: 0,
  },
}));
const FirstStep = props => {
  const classes = useStyles();
  const {data} = props;
  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8} className={classes.marginBottom}>
        <HeaderSection
          title={data.title}
          subTitleVariant="h5"
          subtitle={data.subtitle}
          className={classes.noMarginBottom}
          gridSize={12}
        />
        <span className={classes.marginBottom}>{data.earnBachelorDegree.title}</span>
        <List>
          {data.earnBachelorDegree.listItems.map(item => (
            <ListItem disableGutters className={classes.noPadding}>
              <IconAlternate
                color={indigo}
                fontIconClass={classes.circle}
                size="extraSmall"
                className={clsx(classes.circle, classes.small, classes.marginRight)}
              />
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
        <Typography variant="body1" color="textPrimary" align="left">
          {data.earnBachelorDegree.bonus}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <YouTube
          videoId={data.earnBachelorDegree.video.id}
          alt={data.earnBachelorDegree.video.title}
        />
      </Grid>
    </Grid>
  );
};

FirstStep.propTypes = {
  data: PropTypes.object,
};

export default FirstStep;
