import React from 'react';
import {Grid, Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {HeaderSection} from 'components/molecules';
import {Section} from 'components/organisms';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  largeCell: {
    minWidth: 300,
    [theme.breakpoints.down('md')]: {
      minWidth: 200,
    },
  },
}));
const AnalyticalCookies = () => {
  const StyledTableCell = withStyles(theme => ({
    root: {
      borderBottom: 'none',
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableCell);

  /**
   * Will be deleted
   */
  function createData(name, type, expiration, settingDate, collectedInformation) {
    return {name, type, expiration, settingDate, collectedInformation};
  }

  const classes = useStyles();
  const rows = [
    createData(
      '_ga',
      'Google Analytics Cookie',
      '2 years after it was set',
      'When user visits website.',
      "Used to track a user's behaviour, e.g how long they stay on a certain page and what page they visit next."
    ),
    createData(
      '_gid',
      ' Google Analytics Cookie',
      '1 day after it was set',
      'When user visits website.',
      "Used to track a user's behaviour, e.g how long they stay on a certain page and what page they visit next."
    ),
  ];
  return (
    <Section>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <HeaderSection title="Cookie Policy" subtitle="Strictly necessary cookies." />
          <TableContainer component={Paper}>
            <Table mx={5}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center" className={classes.row}>
                    COOKIE NAME
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.row}>
                    TYPE OF COOKIE
                  </StyledTableCell>
                  <StyledTableCell align="center">WHEN THE COOKIES WILL EXPIRE</StyledTableCell>
                  <StyledTableCell align="center">WHEN IS THE COOKIE SET</StyledTableCell>
                  <StyledTableCell align="center">
                    HOW THE INFORMATION COLLECTED BY THE COOKIES IS USED
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.name}>
                    <StyledTableCell align="center" className={classes.row}>
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.row}>
                      {row.type}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.row}>
                      {row.expiration}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.row}>
                      {row.settingDate}
                    </StyledTableCell>
                    <StyledTableCell align="center" className={classes.row}>
                      {row.collectedInformation}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Section>
  );
};

export default AnalyticalCookies;
