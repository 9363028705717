import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Image} from 'components/atoms';
import {Section, SectionAlternate, CardProduct} from 'components/organisms';
import {HeaderSection} from 'components/molecules';
import {contact} from '../About/data';
import {headerData, materials} from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionPartners: {
    boxShadow: '0 5px 20px 0 rgba(90, 202, 157, 0.05)',
    '& .section-alternate__content': {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  spacing: {
    margin: '30px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    '& ul': {
      marginLeft: '15px',
    },
    '& li': {
      marginLeft: '15px',
    },
  },
  cardTitle: {
    fontWeight: 700,
  },
  cardRoot: {
    display: 'flex',
    margin: '50px auto',
  },
  accordion: {
    display: 'block',
  },
  image: {
    height: '100%',
  },
  media: {
    height: '20%',
    width: '20%',
    margin: 'auto 15px',
  },
  descriptionStyle: {
    width: '100%',
  },
}));

const Press = () => {
  const classes = useStyles();
  const CardMediaContent = props => (
    <Image {...props} className={classes.image} lazyProps={{width: 'auto', height: 'auto'}} />
  );

  const CardContent = ({title, description}) => (
    <div className={classes.cardContent}>
      <Typography variant="body1" color="textPrimary" className={classes.cardTitle} gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="textPrimary">
        {description}
      </Typography>
    </div>
  );

  return (
    <div className={classes.root}>
      <Section>
        <HeaderSection
          title={headerData.title}
          titleVariant="h2"
          subtitle={headerData.subtitle}
          subtitleVariant="h4"
        />
        {headerData.theyTalkAboutUs.map(testimonial => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="body1"> {testimonial.title} </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordion}>
              {testimonial &&
                testimonial.articles.map(article => (
                  <CardProduct
                    mediaClassName={classes.media}
                    className={classes.cardRoot}
                    mediaContent={<CardMediaContent src={article.image} alt={article.title} />}
                    cardContent={
                      <CardContent title={article.title} description={article.description} />
                    }
                  />
                ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Section>
      <SectionAlternate>
        <HeaderSection
          title={materials.title}
          description={materials.description}
          descriptionClassName={classes.descriptionStyle}
        />
      </SectionAlternate>
      <Section>
        <HeaderSection title={contact.title} description={contact.description} />
      </Section>
    </div>
  );
};

export default Press;
