import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Avatar, Typography, Grid, Divider} from '@material-ui/core';
import {Image} from 'components/atoms';
import {CardProduct} from 'components/organisms';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  title: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(6),
    },
  },
  sectionAlternate: {
    '& .section-alternate__content': {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
  },
  searchInputContainer: {
    background: theme.palette.alternate.main,
    padding: theme.spacing(2),
    boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.11)',
    borderRadius: theme.spacing(1),
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: theme.spacing(2),
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  searchButton: {
    maxHeight: 45,
    minWidth: 135,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
  },
  cardProduct: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: theme.spacing(1),
    '& .card-product__content': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(0, 0, 20, 0),
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  button: {
    minWidth: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 420,
    },
  },
  answerCount: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1),
    background: theme.palette.secondary.light,
    color: 'white',
    fontWeight: 300,
  },
}));

const Articles = props => {
  const {data, className, ...rest} = props;
  const classes = useStyles();

  const BlogMediaContent = bmcProps => (
    <Image {...bmcProps} className={classes.image} lazyProps={{width: '100%', height: '100%'}} />
  );

  const BlogContent = ({title, subtitle, author, date}) => (
    <div className={classes.blogContent}>
      <Typography variant="h6" color="textPrimary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {subtitle}
      </Typography>
      <div style={{flexGrow: 1}} />
      <Divider className={classes.divider} />
      <div className={classes.list}>
        <div className={classes.avatarContainer}>
          <Avatar {...author.photo} alt={author.name} className={classes.avatar} />
          <Typography variant="body2" color="textPrimary">
            {author.name}
          </Typography>
        </div>
        <Typography variant="overline" color="textSecondary">
          {date}
        </Typography>
      </div>
    </div>
  );

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Grid item xs={12} sm={6} md={4} key={index}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <CardProduct
                variant="outlined"
                liftUp
                className={classes.cardProduct}
                mediaContent={<BlogMediaContent {...item.cover} alt={item.title} />}
                cardContent={
                  <BlogContent
                    title={item.title}
                    subtitle={item.subtitle}
                    author={item.author}
                    date={item.date}
                  />
                }
              />
            </a>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Articles.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Articles;
