import React from 'react';
import PropTypes from 'prop-types';
import {
  InstagramWidget,
  HeroCta,
  Section,
  SectionAlternate,
  DiscoverMakeSchool,
} from 'components/organisms';
import {HeaderSection} from 'components/molecules';
import {LifeOutside} from './Components';

const Housing = props => {
  const {
    data: {heroData, housingForOnCampus, lifeOutSide},
  } = props;
  return (
    <div>
      <Section>
        <HeroCta data={heroData} />
      </Section>
      <SectionAlternate>
        <HeaderSection {...housingForOnCampus} />
      </SectionAlternate>
      <Section>
        <LifeOutside title={lifeOutSide.title} data={lifeOutSide.images} />
      </Section>
      <SectionAlternate>
        <DiscoverMakeSchool />
      </SectionAlternate>
      <Section>
        <InstagramWidget />
      </Section>
    </div>
  );
};

Housing.propType = {
  openModal: PropTypes.func,
  data: PropTypes.object,
};

export default Housing;
