import React from 'react';

import PropTypes from 'prop-types';
import {HeaderSection} from 'components/molecules';
import {Grid} from '@material-ui/core';
import {Image} from 'components/atoms';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  image: {
    borderRadius: 10,
  },
});
const LifeOutside = props => {
  const {title, data} = props;
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <HeaderSection title={title} />
      <Grid container justify="center" spacing={4}>
        {data.map(image => (
          <Grid item xs={12} md={6} justify="center">
            <Image src={image.imgUrl} alt={image.imgUrl} className={classes.image} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

LifeOutside.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};

export default LifeOutside;
