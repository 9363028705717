import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Divider} from '@material-ui/core';
import {Topbar, Footer, Sidebar} from './components';
import useCookie from '../../data/use-cookie';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
});

const Main = ({children, themeMode}) => {
  const [userType, _] = useCookie('_makeschool');
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const footerPages = [
    {
      groupTitle: 'Additional Information',
      pages: [
        {
          title: `Bachelor's in CS`,
          href: '/computer-science-degree/',
        },
        {
          title: `Online Courses`,
          href: process.env.GATSBY_MS_START_LEARNING_LINK,
        },
        {
          title: 'About',
          href: '/about/',
        },
      ],
    },
    {
      groupTitle: '',
      pages: [
        {
          title: `Press`,
          href: '/press/',
        },
        {
          title: `Employers`,
          href: '/employers/',
        },
        {
          title: `Blog`,
          href: '/blog/',
        },
      ],
    },
    {
      groupTitle: '',
      pages: [
        {
          title: `Careers`,
          href: '/careers/',
        },
        {
          title: `Email Us`,
          href: 'mailto:hello@makeschool.org',
        },
        {
          title: `Faculty & Student Log In`,
          href: process.env.GATSBY_MS_LOGIN_ENDPOINT,
        },
      ],
    },
    userType && {
      groupTitle: '',
      pages: [
        userType === 'admin' && {
          title: 'Admin',
          href: '/admin',
        },
        userType === 'student' && {
          title: `Portal`,
          href: '/portal',
        },
        {
          title: `Log Out`,
          href: '/logout/',
        },
      ].filter(Boolean),
    },
  ].filter(Boolean);

  const pages = {
    bachelors: {
      title: "Bachelor's in CS",
      id: 'bachelors-in-cs',
      children: {
        school: {
          groupTitle: 'The Program',
          pages: [
            {
              title: 'Fall 2021',
              href: '/computer-science-degree/fall/',
            },
            {
              title: `Enrollment Options`,
              href: '/computer-science-degree/enrollment-options/',
            },
            {
              title: `Faculty`,
              href: '/computer-science-degree/community/faculty/',
            },
            {
              title: `FAQs`,
              href: '/computer-science-degree/faq/#the-program',
            },
          ],
        },
        academics: {
          groupTitle: 'Academics',
          pages: [
            {
              title: `Overview`,
              href: '/computer-science-degree/',
            },
            {
              title: `Curriculum`,
              href: '/computer-science-degree/academics/',
            },
            {
              title: `Industry Opportunities`,
              href: '/computer-science-degree/industry-opportunities/',
            },
          ],
        },
        community: {
          groupTitle: 'Community',
          pages: [
            {
              title: `Student Life`,
              href: '/computer-science-degree/student-life/',
            },
            {
              title: `Housing`,
              href: '/computer-science-degree/housing/',
            },
            {
              title: `Housing FAQs`,
              href: '/computer-science-degree/faq/#housing',
            },
            {
              title: `Students and Alumni`,
              href: '/computer-science-degree/community/students/',
            },
            {
              title: `Board of Directors`,
              href: '/computer-science-degree/community/board/',
            },
          ],
        },
        outcomes: {
          groupTitle: 'Outcomes',
          pages: [
            {
              title: `Overview`,
              href: '/computer-science-degree/outcomes/',
            },
            {
              title: `Demographic and Outcomes Data`,
              href: '/computer-science-degree/outcomes/data/',
            },
          ],
        },
      },
    },
    admissions: {
      title: 'Admissions',
      id: 'admissions',
      children: {
        admissions: {
          groupTitle: 'Applying to Make School',
          pages: [
            {
              title: `Overview`,
              href: '/computer-science-degree/admissions/',
            },
            {
              title: `First-Year Applicants`,
              href: '/computer-science-degree/admissions/first-year-applicants/',
            },
            {
              title: `Transfer Applicants`,
              href: '/computer-science-degree/admissions/transfer-applicants/',
            },
            {
              title: `International Applicants`,
              href: '/computer-science-degree/admissions/international-applicants/',
            },
            {
              title: `Asylees, DACA Recipients, and Undocumented Students`,
              href: '/computer-science-degree/admissions/undocumented-applicants/',
            },
            {
              title: `Parents`,
              href: '/computer-science-degree/parents/',
            },
            {
              title: `FAQs`,
              href: '/computer-science-degree/faq/#application-and-admissions',
            },
          ],
        },
      },
    },
    tuition: {
      title: 'Tuition & Aid',
      id: 'tuition-and-aid',
      children: {
        tuition: {
          groupTitle: 'Costs',
          pages: [
            {
              title: `Overview`,
              href: '/computer-science-degree/tuition-and-aid/',
            },
          ],
        },
        aid: {
          groupTitle: 'Financial Aid & Loans',
          pages: [
            {
              title: `EIBR Protection Plan`,
              href: '/computer-science-degree/tuition-and-aid/eibr-protection-plan/',
            },
            {
              title: `Applying for Private Loans`,
              href: '/computer-science-degree/tuition-and-aid/private-loans/',
            },
            {
              title: `Scholarship Resources`,
              href: '/computer-science-degree/tuition-and-aid/scholarship-resources/',
            },
            {
              title: `FAQs`,
              href: '/computer-science-degree/faq/#tuition-and-aid',
            },
          ],
        },
      },
    },
    login: {
      title: 'Login & More',
      id: 'login-and-more',
      children: {
        login: {
          groupTitle: 'Login',
          pages: [
            {
              title: `Applicant Login`,
              href: process.env.GATSBY_MS_APPLY_LINK,
            },
            {
              title: `Student Login`,
              href: process.env.GATSBY_MS_LOGIN_ENDPOINT,
            },
            {
              title: `Mediabook Login`,
              href: `${process.env.GATSBY_MS_LOGIN_ENDPOINT}?redirect_to=${process.env.GATSBY_MS_START_LEARNING_LINK}`,
            },
            {
              title: `Mediabook Signup`,
              href: `${process.env.GATSBY_MS_SIGNUP_ENDPOINT}?redirect_to=${process.env.GATSBY_MS_START_LEARNING_LINK}`,
            },
          ],
        },
        mediabook: {
          groupTitle: 'Mediabook',
          pages: [
            {
              title: `Online Courses`,
              href: process.env.GATSBY_MS_START_LEARNING_LINK,
            },
            {
              title: `Tutorials`,
              href: `${process.env.GATSBY_MS_START_LEARNING_LINK}tutorials/`,
            },
          ],
        },
        makeSchool: {
          groupTitle: 'Make School',
          pages: [
            {
              title: `Blog`,
              href: '/blog/',
            },
            {
              title: `About Us`,
              href: '/about/',
            },
            {
              title: `Code of Conduct`,
              href: '/code-of-conduct/',
            },
            {
              title: `Careers`,
              href: '/careers/',
            },
            {
              title: `Press`,
              href: '/press/',
            },
          ],
        },
      },
    },
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} themeMode={themeMode} />
      <Sidebar onClose={handleSidebarClose} open={open} variant="temporary" pages={pages} />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={footerPages} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
