import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {FormControl, OutlinedInput, InputAdornment} from '@material-ui/core';
import {Icon} from 'components/atoms';
import {HeaderSection} from 'components/molecules';
import {Section} from 'components/organisms';
import {FaPaperPlane} from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    backgroundSize: 'cover',
    // borderRadius: theme.spacing(2),
  },
  textWhite: {
    color: 'white',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    maxWidth: 400,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiInputAdornment-root i': {
      color: 'white !important',
    },
  },
}));

const Newsletter = props => {
  const {className, ...rest} = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Section>
        <>
          <HeaderSection
            title={<span className={classes.textWhite}>Subscribe To Our Newsletter</span>}
            subtitle={
              <span className={classes.textWhite}>
                Sign up for our newsletter to stay up to date on news, events, and student stories.
              </span>
            }
          />
          <div className={classes.inputContainer}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <Icon reactIcon={FaPaperPlane} />
                  </InputAdornment>
                }
                placeholder="Enter your email"
              />
            </FormControl>
          </div>
        </>
      </Section>
    </div>
  );
};

Newsletter.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Newsletter;
