import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  displayFlex: {
    display: 'flex',
  },
  center: {justifyContent: 'center'},
  left: {justifyContent: 'flex-start'},
  right: {justifyContent: 'flex-end'},
});
const BasicAlert = props => {
  const {data, align, className, ...rest} = props;
  const classes = useStyles();
  let alignAlert = classes.center;
  if (align === 'left') {
    alignAlert = classes.left;
  } else if (align === 'right') {
    alignAlert = classes.right;
  }

  return (
    <Alert {...rest} className={clsx(classes.root, classes.displayFlex, alignAlert, className)}>
      {data}
    </Alert>
  );
};

BasicAlert.propTypes = {
  data: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.bool,
  align: PropTypes.oneOf(['right', 'left', 'center']),
};

export default BasicAlert;
